import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Space, Table, Tag, Input } from 'antd';

function Contest() {
    let navigate = useNavigate();

    // 표리스트
    let [data, setData] = useState([]);

    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/contest", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.list);
            setData(response.data.data.list);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])
    console.log();

    // 검색
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/contest", {
            keyword:value,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.list);
            setData(response.data.data.list);
		})
		.catch(function (error) {
			console.log(error);
		})
    }

    // 삭제하기
    function delete_item(id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/contest", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.replace("/Investment/Contest")
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }

    // 표 틀
    const columns = [
        {
            title: '상태',
            key: 'contest_status',
            render: (_, record) => (
                record.contest_status == 1 ?
                    <>공모예정</>
                : record.contest_status == 2 ?
                    <>공모중</>
                : record.contest_status == 3 ?
                    <>공모마감</>
                :""
                
            ),
        },
        {
            title: '공모명',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '목표금액',
            dataIndex: 'cost',
            render: (_, record) => (
                <>
                    {record.cost.toLocaleString()}
                </>
            ),
        },
        {
            title: '현재 모인 금액',
            key: 'now_cost',
            render: (_, record) => (
                <>
                    {record.now_cost.toLocaleString()}
                </>
            ),
        },
		{
            title: '공모일정',
            key: 'at',
            render: (_, record) => (
                <>
                    {record.opened_at.substr(0, 10)} ~ {record.closed_at.substr(0, 10)}
                </>
            ),
        },
		{
            title: '실제 투자일',
            key: 'real_stocked_at',
            dataIndex: 'real_stocked_at',
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
                    <button onClick={()=>{navigate("/Investment/ContestRg/" + record.id)}}>수정</button>
                    <button onClick={()=>{delete_item(record.id)}}>삭제</button>
                </Space>
            ),
        },
    ];
	
    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>투자 관리</li>
                    <li>공모 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>공모 관리</h2>
                    <div className='Function-btn-wrap'>
						<Search
							placeholder="검색어를 입력하세요"
							onSearch={onSearch}
							style={{
								width: 200,
							}}
						/>
                        <button className='Registration-btn' onClick={()=>{navigate("/Investment/ContestRg/" + "Registration")}}>
                            <i className='xi-plus-thin'></i>
                            등록
                        </button>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <div className='table-wrap'>
                        <Table columns={columns} dataSource={data} rowKey={(render)=> render.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contest;