import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

// 앤트디자인
import { Input } from 'antd';

// API
import axios from 'axios';

function Login({ shose, setShose }) {
    let navigate = useNavigate();

    // 메인 api 데이터
    let [data, setData] = useState();

	// 이메일, 비밀번호
	let [email, setEmail] = useState();
	let [password, setPassword] = useState();

	function inquiryEnroll() {
        axios.post("https://api.kjeonse.com/api/admin/login", {
			email: email,
			password: password,
		})
		.then(function (response) {
            if (!response.data.data.original.data.token) {
                alert(response.data.data.original.msg);
            }else{
                localStorage.setItem( 'k-Jeonse-admin-token', response.data.data.original.data.token.access_token );
                navigate("/Homepage/PopUp");
            }
		})
		.catch(function (error) {
			console.log(error);
		});
    }

    return (
        <div className='Login-page'>
            <div className='Login-box'>
                <div className='logo-wrap'>
                    <img src={require("../images/LOGO.png")} alt="" />
                </div>
                <p className='mant-p'>
                    Please log in or sign up for an account
                </p>
                <div className='Login-input-wrap'>
                    <div>
                        <p>Username</p>
                        <Input placeholder="name@domain.com" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                    </div>
                    <div>
                        <p>Password</p>
                        <Input.Password value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                    </div>
                </div>
                <button onClick={()=>{inquiryEnroll()}} className='Login-btn'>
                    로그인
                </button>
            </div>
        </div>
    );
}

export default Login;