import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Space, Table, Tag, Input } from 'antd';

function Withdrawal() {
    let navigate = useNavigate();

    // 표리스트
    let [list, setList] = useState([]);
    // 설문
    let [survey, setSurvey] = useState([]);

    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/leaved", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data);
            setList(response.data.data.list);
            setSurvey(response.data.data.survey);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])
    console.log();

     // 검색
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/leaved/user", {
            keyword:value,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data);
            setList(response.data.data.list);
            setSurvey(response.data.data.survey);
		})
		.catch(function (error) {
			console.log(error);
		})
    }

    // 삭제하기
    function delete_item(id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/leaved/user", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.replace("/Membership/Withdrawal")
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }

    // 리스트배열 설문리스트배열 합치기
    list.map(item => {

        let survey_array = [];

        survey.forEach(surveyItem => {
            if(item.id == surveyItem.user_id) {
                survey_array.push(surveyItem.title);
            }
        });

        item.survey = survey_array.join();
    });

    console.log('e', list);

    // 표 틀
    const columns = [
        {
            title: '회원명',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '이메일',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '생년월일',
            key: 'birth',
            dataIndex: 'birth',
        },
		{
            title: '전화번호',
            key: 'phone',
            dataIndex: 'phone',
        },
		{
            title: '설문',
            key: 'survey',
            dataIndex: 'survey',
        },
		{
            title: '가입일',
            key: 'created_at',
            render: (_, record) => (
                record.created_at.substr(0, 10)
            ),
        },
		{
            title: '요청일',
            key: 'leaved_at',
            render: (_, record) => (
                record.leaved_at.substr(0, 10)
            ),
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
                    <button onClick={()=>{delete_item(record.id)}}>삭제</button>
                </Space>
            ),
        },
    ];
	
    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>회원 관리</li>
                    <li>탈퇴회원 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>탈퇴회원 관리</h2>
                    <div className='Function-btn-wrap'>
                        <Search
							placeholder="검색어를 입력하세요"
							onSearch={onSearch}
							style={{
								width: 200,
							}}
						/>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <div className='table-wrap'>
                        <Table columns={columns} dataSource={list} rowKey={(render)=> render.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Withdrawal;