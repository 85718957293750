import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Space, Table, Tag, Input, Select } from 'antd';

function MemberAssets() {
    let navigate = useNavigate();

    // 유저리스트
    let [user_list , setUser_list] = useState([]);

    // 전세 투자금
    let [user_total_sum_stock_estate, setUser_total_sum_stock_estate] = useState([]);

    // 전세 예치금
    let [user_total_have_estate_amount, setUser_total_have_estate_amount] = useState([]);

    // 일반 투자금
    let [user_total_sum_stock_amount, setUser_total_sum_stock_amount] = useState([]);

    // 일반 예치금
    let [user_total_have_amount, setUser_total_have_amount] = useState([]);

    // 총 수익금
    let [user_total_sum_dividend, setUser_total_sum_dividend] = useState([]);


    user_list.map(item=>{
        
        let user_total_sum_stock_estate_num = 0;
        let user_total_have_estate_amount_num = 0;
        let user_total_sum_stock_amount_num = 0;
        let user_total_have_amount_num = 0;
        let user_total_sum_dividend_num = 0;
        // 총보유자산
        let user_total_amount_num = 0;
        

        user_total_sum_stock_estate.forEach(amountItem => {
            if (item.id == amountItem.user_id) {
                user_total_sum_stock_estate_num+= Number(amountItem.totalSumAmount);
            }
        });

        user_total_have_estate_amount.forEach(amountItem => {
            if (item.id == amountItem.user_id) {
                user_total_have_estate_amount_num+= Number(amountItem.totalSumAmount);
            }
        });

        user_total_sum_stock_amount.forEach(amountItem => {
            if (item.id == amountItem.user_id) {
                user_total_sum_stock_amount_num+= Number(amountItem.totalSumAmount);
            }
        });

        user_total_have_amount.forEach(amountItem => {
            if (item.id == amountItem.user_id) {
                user_total_have_amount_num+= (Number(amountItem.chargeSumAmount) - Number(amountItem.withDrawSumAmount));
            }
        });

        user_total_sum_dividend.forEach(amountItem => {
            if (item.id == amountItem.user_id) {
                user_total_sum_dividend_num+= Number(amountItem.dividendSumAmount);
            }
        });

        item.user_total_sum_stock_estate = user_total_sum_stock_estate_num;
        item.user_total_have_estate_amount = user_total_have_estate_amount_num;
        item.user_total_sum_stock_amount = user_total_sum_stock_amount_num;
        item.user_total_have_amount = user_total_have_amount_num;
        item.user_total_sum_dividend = user_total_sum_dividend_num;

        item.user_total_amount = user_total_have_amount_num + user_total_have_estate_amount_num + user_total_sum_dividend_num;

    });
    console.log(user_list);



    // 검색
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/user/assets", {
            keyword:value,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data);
            let list = response.data.data;
            console.log(list);
            setUser_list(list.user_list);
            setUser_total_sum_stock_estate(list.user_total_sum_stock_estate);
            setUser_total_have_estate_amount(list.user_total_have_estate_amount);
            setUser_total_sum_stock_amount(list.user_total_sum_stock_amount);
            setUser_total_have_amount(list.user_total_have_amount);
            setUser_total_sum_dividend(list.user_total_sum_dividend);
		})
		.catch(function (error) {
			console.log(error);
		})
    }


    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/assets", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data);
            let list = response.data.data;
            console.log(list);
            setUser_list(list.user_list);
            setUser_total_sum_stock_estate(list.user_total_sum_stock_estate);
            setUser_total_have_estate_amount(list.user_total_have_estate_amount);
            setUser_total_sum_stock_amount(list.user_total_sum_stock_amount);
            setUser_total_have_amount(list.user_total_have_amount);
            setUser_total_sum_dividend(list.user_total_sum_dividend);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])
    console.log();

    // 표 틀
    const columns = [
        {
            title: '회원명',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '전화번호',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '총 보유자산',
            dataIndex: 'user_total_amount',
            key: 'user_total_amount',
            render: (_, record) => (record.user_total_amount.toLocaleString()),
        },
        {
            title: '전세 투자금',
            key: 'user_total_sum_stock_estate',
            dataIndex: 'user_total_sum_stock_estate',
            render: (_, record) => (record.user_total_sum_stock_estate.toLocaleString()),
        },
		{
            title: '전세 예치금',
            key: 'user_total_have_estate_amount',
            dataIndex: 'user_total_have_estate_amount',
            render: (_, record) => (record.user_total_have_estate_amount.toLocaleString()),
        },
		{
            title: '일반 투자금',
            key: 'user_total_sum_stock_amount',
            dataIndex: 'user_total_sum_stock_amount',
            render: (_, record) => (record.user_total_sum_stock_amount.toLocaleString()),
        },
		{
            title: '일반 예치금',
            key: 'user_total_have_amount',
            dataIndex: 'user_total_have_amount',
            render: (_, record) => (record.user_total_have_amount.toLocaleString()),
        },
		{
            title: '총 수익금',
            key: 'user_total_sum_dividend',
            dataIndex: 'user_total_sum_dividend',
            render: (_, record) => (record.user_total_sum_dividend.toLocaleString()),
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
                    <button onClick={()=>{entry_pop(record.id)}}>입금</button>
                </Space>
            ),
        },
    ];

    // 입금 팝업
    let [pop, setPop] = useState(false);

    // 입금할 회원 아이디
    let [entry_id, setEntry_id] = useState();

    // 입금 타입
    // let [entry_type, setEntry_type] = useState(0)
    // const statusChange = (value) => {setEntry_type(value)};

    // 입금할 일반 예치금
	let [amount_comma,setAmount_comma] = useState("");
	let [amount,setAmount] = useState("");
	useEffect(()=>{setAmount(commaOut(amount_comma))},[amount_comma]);

    // 입금할 전세 예치금
	let [charter_comma,setCharter_comma] = useState("");
	let [charter,setCharter] = useState("");
	useEffect(()=>{setCharter(commaOut(charter_comma))},[charter_comma]);


    // 입금할 회원 아이디값 가져오면서 팝업 열기
    function entry_pop(id) {
        setPop(true);
        setEntry_id(id);
    }

    // 입금 하기
    function entry() {
        axios.post("https://api.kjeonse.com/api/admin/update/user/assets", {
            id: entry_id,
            amount : amount,
            estate_amount : charter, 
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.success);
            if (response.data.success) {
                alert("예치금 입금이 완료되었습니다")
                setPop(false)
            }else{
                alert("예치금 입금을 실패하였습니다")
            }

		})
		.catch(function (error) {
			console.log(error);
		})
    }


    // 콤마찍는 함수
	function comma(e) {
		let value = e.target.value;
		value = Number(value.replaceAll(',', ''));
		if(isNaN(value)) {
			return(0);
		}else {
			const formatValue = value.toLocaleString('ko-KR');
			return(formatValue);
			
		}
	}
	// 콤마 지우기 함수
	function commaOut(e) {
		const number = Number(e.toString().split(',').join(""));
		return(number)
	}


    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>입출금 관리</li>
                    <li>회원 자산 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>회원 자산 관리</h2>
                    <div className='Function-btn-wrap'>
						<Search
							placeholder="검색어를 입력하세요"
							onSearch={onSearch}
							style={{
								width: 200,
							}}
						/>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <div className='table-wrap'>
                        <Table columns={columns} dataSource={user_list} rowKey={(render)=> render.id} />
                    </div>
                </div>
                {
                    pop ?
                    <div className='White-box pop-box'>
                        <div className='write-h2-wrap'>
                            <h2>예치금 입금</h2>
                        </div>
                        <div className='write-list-wrap'>
                            <ul className='write-list'>
                                {/* <li>
                                    <p className='labal-p'>입금 유형</p>
                                    <Select
                                        value={entry_type}
                                        style={{
                                            width: 120,
                                        }}
                                        onChange={statusChange}
                                        options={[
                                            {
                                            value: 0,
                                            label: '예치금',
                                            },
                                            {
                                            value: 1,
                                            label: '전세금',
                                            },
                                        ]}
                                    />
                                </li> */}
                                <li>
                                    <p className='labal-p'>일반 예치금</p>
                                    <div className='input-wrap'>
                                        <div className='input-box'>
                                            <input type="text" value={amount_comma} onChange={(e)=>{setAmount_comma(comma(e))}} />
                                            <div className='Unit'>원</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className='labal-p'>전세 예치금</p>
                                    <div className='input-wrap'>
                                        <div className='input-box'>
                                            <input type="text" value={charter_comma} onChange={(e)=>{setCharter_comma(comma(e))}} />
                                            <div className='Unit'>원</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='Save-btn-wrap'>
                                        <button className='cancel-btn' onClick={()=>{setPop(false)}}>취소</button>
                                        <button className='Save-btn' onClick={()=>{entry()}}>입금</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    :""
                }
                
            </div>
        </div>
    )
}

export default MemberAssets;