import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Space, Table, Tag, Input, Select } from 'antd';

function Contest() {
    let navigate = useNavigate();

    // 표리스트
    let [data, setData] = useState([]);

    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/estate", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.list);
            setData(response.data.data.list);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])

    // 상태 Select
    let [type , setType] = useState(0);
	const handleChange = (value) => {
		setType(value);
	};

    // 검색
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/estate", {
            keyword:value,
            type:type,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.list);
            setData(response.data.data.list);
		})
		.catch(function (error) {
			console.log(error);
		})
    }

    // 삭제하기
    function delete_item(id) {
        console.log(id);
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/estate", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.replace("/Charter/Charter")
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }

    // 표 틀
    const columns = [
        {
            title: '계약명',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => <button className='blue-btn' onClick={()=>{navigate("/Charter/CharterRg/" + record.id)}}>{record.title}</button> ,
        },
        {
            title: '임대인명',
            dataIndex: 'lessor_name',
            key: 'lessor_name',
        },
        {
            title: '임대인 연락처',
            dataIndex: 'lessor_phone',
            key: 'lessor_phone',
        },
        {
            title: '주소',
            key: 'address',
            dataIndex: 'address',
        },
		{
            title: '계약기간',
            key: 'at',
            render: (_, record) => (
                <>
                    {record.contractor_open_at.substr(0, 10)} ~ {record.contractor_closed_at.substr(0, 10)}
                </>
            ),
        },
		{
            title: '승인',
            key: 'admin_approve',
            render: (_, record) => (
                record.admin_approve == 0 ?
                    <div className='point-wrap'><span className='red-Point'></span>미승인</div>
                : <div className='point-wrap'><span className='blue-Point'></span>승인</div>
            ),
        },
		{
            title: '매칭상태',
            key: 'isMatching',
            render: (_, record) => (
                !record.isMatching ?
                    <div className='point-wrap'><span className='red-Point'></span>매칭전</div>
                : <div className='point-wrap'><span className='blue-Point'></span>매칭완료</div>
            ),
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
					<button onClick={()=>{delete_item(record.id)}}>삭제</button>
                </Space>
            ),
        },
    ];

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>전세 관리</li>
                    <li>전세 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>전세 관리</h2>
                    <div className='Function-btn-wrap'>
						<Select
							defaultValue="전체"
							style={{
								width: 120,
							}}
							onChange={handleChange}
							options={[
                                {
                                value: 0,
                                label: '전체',
                                },
								{
								value: 1,
								label: '임대인명',
								},
								{
								value: 2,
								label: '주소',
								},
							]}
						/>
						<Search
							placeholder="검색어를 입력하세요"
							onSearch={onSearch}
							style={{
								width: 200,
							}}
						/>
                        <button className='Registration-btn' onClick={()=>{navigate("/Charter/CharterRg/" + "Registration")}}>
                            <i className='xi-plus-thin'></i>
                            등록
                        </button>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <div className='table-wrap'>
                        <Table columns={columns} dataSource={data} rowKey={(render)=> render.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contest;