import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Pagination } from 'antd';

function Banner() {
    let navigate = useNavigate();

    // 리스트
    let [data_list, setData_list] = useState([]);
    // 토탈 리스트 개수
    let [total, setTotal] = useState("");
    // 한페이지에 보여줄 양
    let [per_page, setPer_page] = useState();
    

    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/banner", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.list);
            setData_list(response.data.data.list.data);
            setTotal(response.data.data.list.total);
            setPer_page(response.data.data.list.per_page)
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])

    console.log()

    // 페이지네이션
	const [current, setCurrent] = useState(1);
	const PaginationChange = (page) => {
		console.log(page);
		setCurrent(page);
	};

    // 삭제하기
    function delete_item(id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/banner", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.replace("/Homepage/Banner")
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>홈페이지 관리</li>
                    <li>베너 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>베너 관리</h2>
                    <div className='Function-btn-wrap'>
                        <button className='Registration-btn' onClick={()=>{navigate("/Homepage/BannerRg/" + "Registration")}}> 
                            <i className='xi-plus-thin'></i>
                            등록
                        </button>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <ul className='img-list-wrap'>
                    {
                        data_list ?
                        data_list.map((item, i)=>{
                            return(
                                <li key={i}>
                                    <div className='img-wrap'>
                                        <img src={item.fileAddress} alt="" />
                                    </div>
                                    <div className='title-wrap'>
                                        <p className='title-p'>{item.title}</p>
                                        <p className='link-p'>{item.url}</p>
                                        <div className='delete-btn-wrap'>
                                            <button onClick={()=>{navigate("/Homepage/BannerRg/" + item.id)}}>수정</button>
                                            <button onClick={()=>{delete_item(item.id)}}>삭제</button>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                        :""
                    }
                </ul>
                <Pagination current={current} onChange={PaginationChange} total={total} defaultPageSize={per_page} />
            </div>
        </div>
    )
}

export default Banner;