import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

function CompanyRg() {
	let navigate = useNavigate();
    let { id } = useParams();
    
    // 제목
    let [title, setTitle] = useState("");
    // 파일
    let [file, setFile] = useState([]);

    // 수정파일
    let [newfile, setNewfile] = useState([]);

    // 수정하기면 기존에 있던 내용뿌리기
    useEffect(()=>{
        if (id != "Registration") {
            axios.post("https://api.kjeonse.com/api/admin/detail/associate", {
                id: id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response.data.data.detail);
                setTitle(response.data.data.detail.title);
                setFile([{
                    name: response.data.data.detail.originFileName,
                    status: 'done',
                    url: response.data.data.detail.fileAddress,
                }])
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    },[])

    const onChange = ({ fileList: newFileList }) => {
        if (id == "Registration") {
            setFile(newFileList);
        }else{
            setFile(newFileList);
            setNewfile(newFileList);
        }
    };

    function save() {
        if (id == "Registration") {
            // 등록하기
            let formData = new FormData();
            formData.append('title', title);
            file.map(item => {
                if (item.originFileObj != undefined) {
                    formData.append('file', item.originFileObj);
                }
            })
            axios.post("https://api.kjeonse.com/api/admin/register/associate", formData,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                if (response.data.success) {
                    alert("저장되었습니다");
                    navigate(-1)
                }else{
                    alert(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }else{
            // 수정하기
            let formData = new FormData();
            formData.append('title', title);
            formData.append('id', id);
            newfile.map(item => {
                if (item.originFileObj != undefined) {
                    formData.append('file', item.originFileObj);
                }
            })
            axios.post("https://api.kjeonse.com/api/admin/update/associate", formData,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                if (response.data.success) {
                    alert("수정되었습니다");
                    navigate(-1)
                }else{
                    alert(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }

    const uploadAutoUploadLock = () => {
        return false;
    }

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>홈페이지 관리</li>
                    <li>제휴사 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>제휴사 관리</h2>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <ul className='write-list'>
                        <li>
                            <p className='labal-p'>제휴사명</p>
							<div className='input-wrap'>
                                <div className='input-box'>
                                    <input type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} placeholder='제휴사명을 입력하세요.' />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className='labal-p'>제휴사 로고</p>
                            <div className='input-wrap'>
                                <Upload
									beforeUpload={uploadAutoUploadLock}
									listType="picture"
									fileList={file}
                                    maxCount={1}
                                    onChange={onChange}
									>
									<Button icon={<UploadOutlined />}>파일 업로드</Button>
								</Upload>
							</div>
                        </li>
                    </ul>
                    <div className='Save-btn-wrap'>
                        <button className='cancel-btn' onClick={()=>{navigate("/Homepage/Company")}}>취소</button>
                        <button className='Save-btn' onClick={()=>{save()}}>{id == "Registration" ? "저장" : "수정"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyRg;