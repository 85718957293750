import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 다음주소검색
import DaumPostcode from 'react-daum-postcode';

// 엔트디자인
import { UploadOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Upload, Switch, DatePicker, Table } from 'antd';
import { Form, Input, Space } from 'antd';
import { Select } from 'antd';
import dayjs from 'dayjs';

// 에디터
import  {  useQuill  }  from  'react-quilljs' ; 
import  'quill/dist/quill.snow.css' ;


function CharterRg() {
	let navigate = useNavigate();
	let { id } = useParams();

	// 날짜 선택
	const { RangePicker } = DatePicker;
	
	// 상태 Select
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};

	// 등록 데이터들 ===========================================================================================================
	// 계약명
	let [title ,setTitle] = useState("");

	// 전세금
	let [contractor_total_amount_comma,setContractor_total_amount_comma] = useState("");
	let [contractor_total_amount,setContractor_total_amount] = useState("");
	useEffect(()=>{setContractor_total_amount(commaOut(contractor_total_amount_comma))},[contractor_total_amount_comma]);

	// 전세기간  시작일
	let [contractor_open_at, setContractor_open_at] = useState("");
	// 전세기간  마감일
	let [contractor_closed_at, setContractor_closed_at] = useState("");

	// 계약일
	let [contractor_created_at, setContractor_created_at] = useState("");

	// 계약금
	let [contractor_payment_comma,setContractor_payment_comma] = useState("");
	let [contractor_payment,setContractor_payment] = useState("");
	useEffect(()=>{setContractor_payment(commaOut(contractor_payment_comma))},[contractor_payment_comma]);

	// 계약금 지급날짜
	let [contractor_payment_at, setContractor_payment_at] = useState("");

	// 중도금
	let [contractor_interim_comma,setContractor_interim_comma] = useState("");
	let [contractor_interim,setContractor_interim] = useState("");
	useEffect(()=>{setContractor_interim(commaOut(contractor_interim_comma))},[contractor_interim_comma]);

	// 중도금 지급날짜
	let [contractor_interim_at, setContractor_interim_at] = useState("");

	// 잔금
	let [contractor_balance_comma,setContractor_balance_comma] = useState("");
	let [contractor_balance,setContractor_balance] = useState("");
	useEffect(()=>{setContractor_balance(commaOut(contractor_balance_comma))},[contractor_balance_comma]);

	// 잔금 지급날짜
	let [contractor_balance_at, setContractor_balance_at] = useState("");

	// 관리자 승인
	let [approve, setApprove] = useState("");
	const allocation_typeChange = (value) => {setApprove(value);};

	// 우편번호
	let [post_code, setPost_code] = useState("");
	// 주소
	let [address, setAddress] = useState("");
	// 상세주소
	let [address_detail, setAddress_detail] = useState("");
	let [modalState , setModalState] = useState(false)
	function onCompletePost(data) {
		setPost_code(data.zonecode)
		setAddress(data.address)
		setModalState(false);
	}
	// 임대인 이름
	let [lessor_name, setLessor_name] = useState("");
	// 임대인 연락처
	let [lessor_phone, setLessor_phone] = useState("");

	// 임차인 이름
	let [lessee_name, setLessee_name] = useState("");
	// 임차인 연락처
	let [lessee_phone, setLessee_phone] = useState("");


	// 비밀번호
	let [password, setPassword] = useState("");

	// 관리자 공지
	let [notice, setNotice] = useState([]);
	// 임대차 계약 관리
	let [contractor, setContractor] = useState([]);
	// 시설 관리 / 문의
	let [qna, setQna] = useState([]);

	// 첨부파일
	let [file, setFile] = useState([]);
	// 첨부파일 수정
	let [fileNewList, setFileNewList] = useState([]);
	// 첨부파일 삭제
	let [fileRemoveList, setFileRemoveList] = useState([]);
	const setFileOnChange = ({ fileList: newFileList }) => {
        if (id == "Registration") {
            setFile(newFileList);
        }else{
            setFile(newFileList);
            setFileNewList(newFileList);
        }
    };
    function setFileOnRemove(file) {setFileRemoveList([...fileRemoveList,file.id])}


	// 수정하기면 기존에 있던 내용뿌리기
	useEffect(()=>{
		if (id != "Registration") {
			axios.post("https://api.kjeonse.com/api/admin/detail/estate", {
				id: id,
			},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
				},
			})
			.then(function (response) {
				console.log(response.data.data);
				let detail = response.data.data.detail;
				setTitle(detail.title);
				setContractor_total_amount_comma(detail.contractor_total_amount);
				setContractor_open_at(detail.contractor_open_at);
				setContractor_closed_at(detail.contractor_closed_at);
				setContractor_created_at(detail.contractor_created_at);
				setContractor_payment_comma(detail.contractor_payment);
				setContractor_payment_at(detail.contractor_payment_at);
				setContractor_interim_comma(detail.contractor_interim);
				setContractor_interim_at(detail.contractor_interim_at);
				setContractor_balance_comma(detail.contractor_balance);
				setContractor_balance_at(detail.contractor_balance_at);
				setApprove(detail.approve);
				setPost_code(detail.post_code);
				setAddress(detail.address);
				setAddress_detail(detail.address_detail);

				setLessor_name(detail.lessor_name);
				setLessor_phone(detail.lessor_phone);

				setLessee_name(detail.lessee_name);
				setLessee_phone(detail.lessee_phone);

				setPassword(detail.password);

				// 파일 이름
				let originFileName = detail.originFileName;
				let fileAddress = detail.fileAddress;
				let file = [{name: originFileName, status: 'done', url: fileAddress,}]
				setFile(file);
				


				// 관리자 공지
				let notice = response.data.data.notice;
				setNotice(notice);
				// 임대차 계약 관리
				let contractor = response.data.data.contractor;
				setContractor(contractor);
				// 시설 관리 / 문의
				let qna = response.data.data.qna;
				setQna(qna);
			})
			.catch(function (error) {
				console.log(error);
			})
		}
	},[])

	function save() {
		if (id == "Registration") {
			// 등록하기
			let formData = new FormData();
			formData.append('cid', id);
			formData.append('title', title);
			formData.append('contractor_total_amount', contractor_total_amount);
			formData.append('contractor_open_at', contractor_open_at);
			formData.append('contractor_closed_at', contractor_closed_at);
			formData.append('contractor_created_at', contractor_created_at);
			formData.append('contractor_payment', contractor_payment);
			formData.append('contractor_payment_at', contractor_payment_at);
			formData.append('contractor_interim', contractor_interim);
			formData.append('contractor_interim_at', contractor_interim_at);
			formData.append('contractor_balance', contractor_balance);
			formData.append('contractor_balance_at', contractor_balance_at);
			formData.append('approve', approve);
			formData.append('post_code', post_code);
			formData.append('address', address);
			formData.append('address_detail', address_detail);
			formData.append('modalState', modalState);
			formData.append('lessor_name', lessor_name);
			formData.append('lessor_phone', lessor_phone);
			formData.append('lessee_name', lessee_name);
			formData.append('lessee_phone', lessee_phone);
			formData.append('password', password);


			file.map(item => {
				if (item.originFileObj != undefined) {
					formData.append('file', item.originFileObj);
				}
			})

			axios.post("https://api.kjeonse.com/api/admin/register/contest", formData,
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
				},
			})
			.then(function (response) {
				console.log(response);
				if (response.data.success) {
					alert("저장되었습니다");
					// navigate(-1)
				}else{
					alert(response.data.msg)
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}else{
			// 수정하기
			let formData = new FormData();
			formData.append('eid', id);
			formData.append('title', title);
			formData.append('contractor_total_amount', contractor_total_amount);
			formData.append('contractor_open_at', contractor_open_at);
			formData.append('contractor_closed_at', contractor_closed_at);
			formData.append('contractor_created_at', contractor_created_at);
			formData.append('contractor_payment', contractor_payment);
			formData.append('contractor_payment_at', contractor_payment_at);
			formData.append('contractor_interim', contractor_interim);
			formData.append('contractor_interim_at', contractor_interim_at);
			formData.append('contractor_balance', contractor_balance);
			formData.append('contractor_balance_at', contractor_balance_at);
			formData.append('approve', approve);
			formData.append('post_code', post_code);
			formData.append('address', address);
			formData.append('address_detail', address_detail);
			formData.append('lessor_name', lessor_name);
			formData.append('lessor_phone', lessor_phone);
			formData.append('lessee_name', lessee_name);
			formData.append('lessee_phone', lessee_phone);
			formData.append('password', password);

			file.map(item => {
				if (item.originFileObj != undefined) {
					formData.append('file', item.originFileObj);
				}
			})

			axios.post("https://api.kjeonse.com/api/admin/update/estate", formData,
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
				},
			})
			.then(function (response) {
				console.log(response);
				if (response.data.success) {
					alert("수정되었습니다");
					// navigate(-1)
				}else{
					alert(response.data.msg)
				}
			})
			.catch(function (error) {
				console.log(error);
			})
		}
    }


	// 표 ////////////////////////////////////////////////////////////////////////////////////////////
	// 관리자 공지 삭제하기
    function Announcement_delete_item(list_id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/estate/notice", {
				eid : id,
                did : list_id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }
	// 관리자 공지 표 틀
    const notice_columns = [
        {
            title: '수신',
            key: 'type',
			render: (_, record) => (
                record.type == 0 ?
					"임대인"
				: record.type == 1 ?
					"임차인"
				: record.type == 2 ?
					"임대인/임차인"
				:""
            ),
        },
        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '작성자',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '작성일',
            key: 'created_at',
            dataIndex: 'created_at',
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
					<button onClick={()=>{navigate("/Charter/Charter_Announcement/" + record.id + "/" + id)}}>수정</button>
					<button onClick={()=>{Announcement_delete_item(record.id)}}>삭제</button>
                </Space>
            ),
        },
    ];



	// 임대차 계약 관리 삭제하기
    function Contract_delete_item(list_id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/estate/contractor", {
				eid : id,
                did : list_id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }
	// 임대차 계약 관리 표 틀
    const contractor_columns = [
        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '작성자',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '작성일',
            key: 'created_at',
            dataIndex: 'created_at',
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
					<button onClick={()=>{navigate("/Charter/Charter_Contract/" + record.id + "/" + id)}}>수정</button>
					<button onClick={()=>{Contract_delete_item(record.id)}}>삭제</button>
                </Space>
            ),
        },
    ];

	// 시설 관리 / 문의 삭제하기
    function qna_delete_item(list_id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/estate/qna", {
				eid : id,
                did : list_id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }
	// 시설 관리 / 문의 표 틀
    const qna_columns = [
		{
            title: '문의유형',
            dataIndex: 'category_title',
            key: 'category_title',
        },
        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
        },
		{
            title: '처리상태',
            key: 'type',
			render: (_, record) => (
                record.type == 0 ?
					"처리요청"
				: record.type == 1 ?
					"처리보류"
				: record.type == 2 ?
					"승인완료"
				: record.type == 3 ?
					"관리자"
				:""
            ),
        },
        {
            title: '작성자',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '작성일',
            key: 'created_at',
            dataIndex: 'created_at',
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
					<button onClick={()=>{navigate("/Charter/Charter_qna/" + record.id + "/" + id)}}>수정</button>
					<button onClick={()=>{qna_delete_item(record.id)}}>삭제</button>
                </Space>
            ),
        },
    ];


	// 콤마찍는 함수
	function comma(e) {
		let value = e.target.value;
		value = Number(value.replaceAll(',', ''));
		if(isNaN(value)) {
			return(0);
		}else {
			const formatValue = value.toLocaleString('ko-KR');
			return(formatValue);
			
		}
	}
	// 콤마 지우기 함수
	function commaOut(e) {
		const number = Number(e.toString().split(',').join(""));
		return(number)
	}

	const uploadAutoUploadLock = () => {
        return false;
    }

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>전세 관리</li>
                    <li>전세 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>전세 관리</h2>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
					<div className='write-h2-wrap'>
						<h2>전세정보</h2>
						<div className='Function-btn-wrap'>
							<button className='cancel-btn' onClick={()=>{navigate(-1)}}>취소</button>
							<button className='Save-btn' onClick={()=>{save()}}>저장</button>
						</div>
					</div>
					<div className='write-list-wrap margin-box'>
						<ul className='write-list'>
							<li>
								<p className='labal-p'>계약명</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} />
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>전세금</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={contractor_total_amount_comma} onChange={(e)=>{setContractor_total_amount_comma(comma(e))}} />
										<div className='Unit'>원</div>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>전세기간</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
											<RangePicker 
											value={[dayjs(contractor_open_at), dayjs(contractor_closed_at)]}
											onChange={(e,data)=>{setContractor_open_at(data[0]); setContractor_closed_at(data[1])}} />
											:
											<RangePicker onChange={(e,data)=>{setContractor_open_at(data[0]); setContractor_closed_at(data[1])}} />
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>계약일</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
											<DatePicker
											value={dayjs(contractor_created_at)}
											onChange={(e,data)=>{setContractor_created_at(data);}} />
											:
											<DatePicker onChange={(e,data)=>{setContractor_created_at(data);}} />
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>계약금</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={contractor_payment_comma} onChange={(e)=>{setContractor_payment_comma(comma(e))}} />
										<div className='Unit'>원</div>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>계약금 지급날짜</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
											<DatePicker
											value={dayjs(contractor_payment_at)}
											onChange={(e,data)=>{setContractor_payment_at(data);}} />
											:
											<DatePicker onChange={(e,data)=>{setContractor_payment_at(data);}} />
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>중도금</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={contractor_interim_comma} onChange={(e)=>{setContractor_interim_comma(comma(e))}} />
										<div className='Unit'>원</div>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>중도금 지급날짜</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
											<DatePicker
											value={dayjs(contractor_interim_at)}
											onChange={(e,data)=>{setContractor_interim_at(data);}} />
											:
											<DatePicker onChange={(e,data)=>{setContractor_interim_at(data);}} />
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>잔금</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={contractor_balance_comma} onChange={(e)=>{setContractor_balance_comma(comma(e))}} />
										<div className='Unit'>원</div>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>잔금 지급날짜</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
											<DatePicker
											value={dayjs(contractor_balance_at)}
											onChange={(e,data)=>{setContractor_balance_at(data);}} />
											:
											<DatePicker onChange={(e,data)=>{setContractor_balance_at(data);}} />
										}
									</div>
								</div>
							</li>
						</ul>
						<ul className='write-list'>
							<li>
								<p className='labal-p'>관리자 승인</p>
								<div className='input-wrap'>
									<Select
										defaultValue="관리자 승인"
										value={approve}
										style={{
											width: 120,
										}}
										onChange={allocation_typeChange}
										options={[
											{
											value: 0,
											label: '비승인',
											},
											{
											value: 1,
											label: '승인',
											},
										]}
									/>
								</div>
							</li>
							{/* 주소 묶음 =========================================================== */}
							<li>
								<p className='labal-p'>주소</p>
								<div className='input-wrap'>
									<div className='input-box Address'>
										<input type="text" value={post_code} />
										<button onClick={()=>{setModalState(true)}}>주소찾기</button>
										{
											modalState ?
											<div className='DaumPostcode-wrap'>
												<div className='close-wrap'>
													<p>주소찾기</p>
													<button className='close-btn' onClick={()=>{setModalState(false)}}><i className='xi-close'></i></button>
												</div>
												<DaumPostcode className='DaumPostcode' onComplete={onCompletePost}></DaumPostcode>
											</div>
											:""
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'></p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input value={address} type="text" />
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'></p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={address_detail} onChange={(e)=>{setAddress_detail(e.target.value)}} placeholder='상세주소' />
									</div>
								</div>
							</li>
							{/* ===================================================================== */}
							<li>
								<p className='labal-p'>임대인명</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text"  value={lessor_name} onChange={(e)=>{setLessor_name(e.target.value)}} />
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>임대인 연락처</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={lessor_phone} onChange={(e)=>{setLessor_phone(e.target.value)}} />
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>임차인</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text"  value={lessee_name} onChange={(e)=>{setLessee_name(e.target.value)}} />
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>임차인 연락처</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={lessee_phone} onChange={(e)=>{setLessee_phone(e.target.value)}} />
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>비밀번호</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>첨부파일</p>
								<div className='input-wrap'>
									<Upload
										beforeUpload={uploadAutoUploadLock}
										fileList={file}
										onChange={setFileOnChange}
										onRemove={setFileOnRemove}
										maxCount={1}
										>
										<Button icon={<UploadOutlined />}>파일 업로드</Button>
									</Upload>
								</div>
							</li>
						</ul>
					</div>
					{
						id != "Registration" ?
						<>
							<div className='write-h2-wrap'>
								<h2>관리자 공지</h2>
								<div className='Function-btn-wrap'>
									<button className='Save-btn' onClick={()=>{navigate("/Charter/Charter_Announcement/" + "Registration" + "/" + id)}}>등록</button>
								</div>
							</div>
							<div className='table-wrap'>
								<Table columns={notice_columns} dataSource={notice} rowKey={(render)=> render.id} />
							</div>
							<div className='write-h2-wrap'>
								<h2>임대차 계약 관리</h2>
								<div className='Function-btn-wrap'>
									<button className='Save-btn' onClick={()=>{navigate("/Charter/Charter_Contract/" + "Registration" + "/" + id)}}>등록</button>
								</div>
							</div>
							<div className='table-wrap'>
								<Table columns={contractor_columns} dataSource={contractor} rowKey={(render)=> render.id} />
							</div>
							<div className='write-h2-wrap'>
								<h2>시설 관리 / 문의</h2>
								<div className='Function-btn-wrap'>
								</div>
							</div>
							<div className='table-wrap'>
								<Table columns={qna_columns} dataSource={qna} rowKey={(render)=> render.id} />
							</div>
						</>
						:""
					}
					
                </div>
            </div>
        </div>
    )
}

export default CharterRg;