import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { UploadOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Upload, Switch, DatePicker, Table } from 'antd';
import { Form, Input, Space } from 'antd';
import { Select } from 'antd';

// 에디터
import  {  useQuill  }  from  'react-quilljs' ; 
import  'quill/dist/quill.snow.css' ;


function Add_investor() {

	let navigate = useNavigate();

	let { id } = useParams();

	// 회원 검색 결과 목록
	let [user_list, setUser_list] = useState([]);

	// 회원 정보 =================================
	// 회원명
	let [user_name, setUser_name] = useState("");

	// 회원아이디
	let [user_id, setUser_id] = useState();

	// 현재투자가능한 예치금
	let [can_use_amount, setCan_use_amount] = useState("");

	// 유저 선택 후, 전세 리스트 데이터를 받을 때 해당 배열 초기화
	let [user_estate_list, setEstateList] = useState([]);



	// 검색하기
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/dividend/user", {
			name:value,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.user);
			setUser_list(response.data.data.user);
		})
		.catch(function (error) {
			console.log(error);
		})
    }


	// 회원 선택하기
	function user_choice(cid , uid) {
		axios.post("https://api.kjeonse.com/api/admin/detail/user", {
			cid: cid,
			uid: uid,
		},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {

			console.log(response.data.data);

			const estate_my_list = response.data.data.can_stock_estate_my_list;
			const estate_stocked_list= response.data.data.stocked_estate_history;
			
			let selectOptionValue = [];
			
			/*
			[ { value = "" , label = "" } ]
			
			*/

			estate_my_list.map(estate => {
				
				estate_stocked_list.forEach(history => {

					if(estate.id == history.estate_id) {
						
						selectOptionValue.push(
							{ 
								value : estate.id, 
								label : `${estate.title + "(" + (estate.contractor_total_amount - history.totalSumStockAmount) + "원)"}`
							}
						);
						return;
					}

				});

			});

		
			console.log('array', selectOptionValue)
			setEstateList(selectOptionValue);

			setCan_use_amount(response.data.data.can_use_amount);

		})
		.catch(function (error) {
			console.log(error);
		})
	}

	// 배당 상세 - 검색
	// 상태 Select
	let [type, setType] = useState();
	const typeChange = (value) => {
		setType(value);
		if (value == 0) {
			setEstate_id(undefined);
		}
	};

	// 전세 아이디
	let [estate_id, setEstate_id] = useState();
	const estate_idChange = (value) => {
		setEstate_id(value);
	}
	
	// 전체 회차
	let columns = [
		{
			title: '이름',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '이메일',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: '전화번호(휴대폰)',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: '선택',
			key: 'action',
			render: (_, record) => (
				<Space size="middle" className='table-btn-wrap'>
					<button onClick={()=>{user_choice(id , record.id); setUser_name(record.name); setUser_id(record.id)}}>선택</button>
				</Space>
			),
		},
	];

	// 투자하기
	let [amount_comma,setAmount_comma] = useState("");
	let [amount, setAmount] = useState("");
	useEffect(()=>{setAmount(commaOut(amount_comma))},[amount_comma]);
	function dividend() {
		if (window.confirm("투자자를 추가하시겠습니까?")) {

			axios.post("https://api.kjeonse.com/api/admin/register/dividend/user", {
				cid: id,
				uid: user_id,
				type: type,
				eid: estate_id,
				amount: amount,
				token: amount/1000,
			},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
				},
			})
			.then(function (response) {
				console.log(response.data.data);
				if (response.data.data.success) {
					alert("추가하였습니다");
					navigate(-1);
				}else{
					alert(response.data.data.msg);
				}
			})
			.catch(function (error) {
				console.log(error);
			})

		} else {

		alert("취소합니다.");

		}
		
	}


	// 콤마찍는 함수
	function comma(e) {
		let value = e.target.value;
		value = Number(value.replaceAll(',', ''));
		if(isNaN(value)) {
			return(0);
		}else {
			const formatValue = value.toLocaleString('ko-KR');
			return(formatValue);
			
		}
	}
	// 콤마 지우기 함수
	function commaOut(e) {
		const number = Number(e.toString().split(',').join(""));
		return(number)
	}

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>투자 관리</li>
                    <li>배당 관리</li>
					<li>투자자 추가</li>
                </ul>
                <div className='title-wrap'>
                    <h2>투자자 추가</h2>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
					<div className='write-h2-wrap'>
						<h2>회원 검색</h2>
						<div className='Function-btn-wrap'>
							<Search
								placeholder="회원명을 입력하세요"
								onSearch={onSearch}
								style={{
									width: 200,
								}}
							/>
							<button className='cancel-btn' onClick={()=>{navigate(-1)}}>뒤로가기</button>
						</div>
					</div>
					<div className='table-wrap'>
						<Table columns={columns} dataSource={user_list} rowKey={(render)=> render.id} />
					</div>
					<div className='write-h2-wrap'>
						<h2>회원정보</h2>
						<div className='Function-btn-wrap'></div>
					</div>
					<div className='contest-Information-wrap margin-box'>
						<h2>{user_name}</h2>
						<ul className='Information-list Information-list-st'>
							<li>
								<p className='labal-p'>현재투자가능한 예치금 :</p>
								<p className='answer-p'>{can_use_amount.toLocaleString()} 원</p>
							</li>
							<li>
								<p className='labal-p'>투자금 유형 선택 :</p>
								<Select
									className='Select-st'
									defaultValue={"유형 선택"}
									onChange={typeChange}
									options={[
										{
											value: 0,
											label: "일반 투자금"
										},
										{
											value: 1,
											label: "전세 투자금"
										}
									]}
								/>
							</li>
							{
								type ?
									<li>
										<p className='labal-p'>내 전세 리스트 :</p>
										<Select
											className='Select-st'
											defaultValue={"리스트 선택"}
											onChange={estate_idChange}
											options={user_estate_list}
										/>
									</li>
								: ""
							}
						</ul>
					</div>
					<div className='write-h2-wrap'>
						<h2>투자금액</h2>
					</div>
					<ul className='write-list margin-box'>
                        <li>
                            <p className='labal-p'>투자금액</p>
                            <div className='input-wrap dividend-input-wrap'>
                                <div className='input-box'>
                                    <input type="text" value={amount_comma} onChange={(e)=>{setAmount_comma(comma(e))}} />
									<div className='Unit'>원</div>
                                </div>
								<button className='dividend-btn' onClick={()=>{dividend()}}>투자하기</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Add_investor;