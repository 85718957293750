import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 다음주소검색
import DaumPostcode from 'react-daum-postcode';

// 엔트디자인
import { UploadOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Upload, Switch, DatePicker } from 'antd';
import { Form, Input, Space } from 'antd';
import { Select } from 'antd';
import dayjs from 'dayjs';

// 에디터
import ReactQuill, { Quill } from "react-quill";
import  'quill/dist/quill.snow.css' ;
import styled from 'styled-components';


function ContestRg() {

	let navigate = useNavigate();
	let { id } = useParams();
	const [ form ] = Form.useForm();

	// 날짜 선택
	const { RangePicker } = DatePicker;

	// 등록 데이터들 ===========================================================================================================
	// 공모명
	let [title ,setTitle] = useState("");
	// 공모상태
	let [status, setStatus] = useState();
	const statusChange = (value) => {setStatus(value);};
	// 주요설명
	let [description, setDescription] = useState("");
	// 주요 특징
	let [feature, setFeature] = useState("");
	// 대표 이미지 ======================================================================================================
	let [main_image_file, setMain_image_file] = useState([]);
	// 대표 이미지 수정
	let [main_image_fileNewList, setMain_image_fileNewList] = useState([]);
	// 대표 이미지 삭제
	let [main_image_fileRemoveList, setMain_image_fileRemoveList] = useState([]);
	const main_image_fileOnChange = ({ fileList: newFileList }) => {
        if (id == "Registration") {
            setMain_image_file(newFileList);
        }else{
            setMain_image_file(newFileList);
            setMain_image_fileNewList(newFileList);
        }
    };
    function main_image_fileOnRemove(file) {setMain_image_fileRemoveList([...main_image_fileRemoveList,file.id])}
	// ======================================================================================================================
	// 공모일정 시작일
	let [opened_at, setOpened_at] = useState("");
	// 공모일정 마감일
	let [closed_at, setClosed_at] = useState("");
	// 실제 투자일
	let [real_stocked_at, setReal_stocked_at] = useState("");
	// 공모액
	let [cost_comma,setCost_comma] = useState("");
	let [cost,setCost] = useState("");
	useEffect(()=>{setCost(commaOut(cost_comma))},[cost_comma]);
	// 1인 최대 금액
	let [can_buy_stock_comma,setCan_buy_stock_comma] = useState("");
	let [can_buy_stock,setCan_buy_stock] = useState("");
	useEffect(()=>{setCan_buy_stock(commaOut(can_buy_stock_comma))},[can_buy_stock_comma]);
	// 현재수량
	let [now_cost,setNow_cost] = useState("");

	// 주소================================================================================================================
	// 우편본호
	let [post_code,setPost_code] = useState("");
	// 주소
	let [address, setAddress] = useState("");
	// 상세주소
	let [address_detail, setAddress_detail] = useState("");
	let [modalState , setModalState] = useState(false)
	function onCompletePost(data) {
		setPost_code(data.zonecode)
		setAddress(data.address)
		setModalState(false);
	}
	// =====================================================================================================================
	// 배당 수익률
	let [expect_return_amount, setExpect_return_amount] = useState("");
	// 배당주기
	let [allocation_type, setAllocation_type] = useState();
	const allocation_typeChange = (value) => {setAllocation_type(value);};
	// 배당 시작일
	let [expect_return_date, setExpect_return_date] = useState("");
	// 배당 마감일
	let [closed_dividend_at, setClosed_dividend_at] = useState("");
	
	// 슬라이드 이미지 ======================================================================================================
	let [slider_image_files, setslider_image_files] = useState([]);
	// 슬라이드 이미지 수정
	let [slider_image_filesNewList, setslider_image_filesNewList] = useState([]);
	// 슬라이드 이미지 삭제
	let [slider_image_filesRemoveList, setslider_image_filesRemoveList] = useState([]);
	const slider_image_filesOnChange = ({ fileList: newFileList }) => {
        if (id == "Registration") {
            setslider_image_files(newFileList);
        }else{
            setslider_image_files(newFileList);
            setslider_image_filesNewList(newFileList);
        }
    };
    function slider_image_filesOnRemove(file) {setslider_image_filesRemoveList([...slider_image_filesRemoveList,file.id])}
	// ======================================================================================================================
	// 대제목
	let [info_title, setInfo_title] = useState("");
	// 기본정보 내용 - 소제목 : 내용
	let [info_row, setInfo_row] = useState([]);
	// 기본정보 내용 - 소제목 : 내용 수정
	let [info_row_new , setInfo_row_new] = useState();
	// 추가한 내용
	useEffect(()=>{
		if (info_row_new) {
			// info_row_new.map(item => {
			// 	info_row.forEach(item2 => {
			// 		if(item.key == item2.key) {
			// 			item.key = 0;
			// 		}
			// 	});
			// });
			// console.log(info_row_new);
			info_row_new.map((item,i)=>{
				if (!item.key) {
					item.key = 0;
				}
			})
			console.log(info_row_new);
		}
	},[info_row_new])


	// 첨부파일 ======================================================================================================
	let [content_files, setcontent_files] = useState([]);
	// 첨부파일 수정
	let [content_filesNewList, setcontent_filesNewList] = useState([]);
	// 첨부파일 삭제
	let [content_filesRemoveList, setcontent_filesRemoveList] = useState([]);
	const content_filesOnChange = ({ fileList: newFileList }) => {
        if (id == "Registration") {
            setcontent_files(newFileList);
        }else{
            setcontent_files(newFileList);
            setcontent_filesNewList(newFileList);
        }
    };
    function content_filesOnRemove(file) {setcontent_filesRemoveList([...content_filesRemoveList,file.id])}
	// ========================================================================================================================
	// 공모 상세 내용
	let [content, setContent] = useState("");
	// ========================================================================================================================

	// 수정하기면 기존에 있던 내용뿌리기
    useEffect(()=>{
        if (id != "Registration") {
            axios.post("https://api.kjeonse.com/api/admin/detail/contest", {
                id: id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response.data.data);
				let detail = response.data.data.detail;
				setTitle(detail.title);
				setStatus(detail.contest_status);
				setDescription(detail.description);
				setFeature(detail.feature);
				setOpened_at(detail.opened_at);
				setClosed_at(detail.closed_at);
				setReal_stocked_at(detail.real_stocked_at);
				setCost_comma(detail.cost);
				setCan_buy_stock_comma(detail.buy_token_count * 1000);
				setNow_cost(detail.now_cost / 1000);
				setPost_code(detail.post_code);
				setAddress(detail.address);
				setAddress_detail(detail.address_detail);
				setExpect_return_amount(detail.expect_return_amount);
				setAllocation_type(detail.allocation_type);
				setExpect_return_date(detail.expect_return_date);
				setClosed_dividend_at(detail.closed_dividend_at);
				setInfo_title(detail.info_title);
				setContent(detail.content);

				setInfo_row(response.data.data.info);
				// 대표이미지
				let main_images = response.data.data.main_images;
				setMain_image_file([{
					id: main_images.id,
					name: main_images.originFileName,
					status: 'done',
					url: main_images.fileAddress,
				}]);
				
				// 슬라이드 이미지
				let slider_images = response.data.data.slider_images;
				let slider_images_copy = [];
				slider_images.map((item,i)=>{
                    slider_images_copy.push({
                        id: item.id,
                        name: item.originFileName,
                        status: 'done',
                        url: item.fileAddress,
                    })
                    setslider_image_files(slider_images_copy);
                })

				// 첨부파일
				let files = response.data.data.files;
				let files_copy = [];
				files.map((item,i)=>{
                    files_copy.push({
                        id: item.id,
                        name: item.originFileName,
                        status: 'done',
                        url: item.fileAddress,
                    })
                    setcontent_files(files_copy);
                })
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    },[])


	function save() {

			if (id == "Registration") {
				// 등록하기
				let formData = new FormData();
				formData.append('title', title);
				formData.append('status', status);
				formData.append('description', description);
				formData.append('feature', feature);
				main_image_file.map(item => {
					if (item.originFileObj != undefined) {
						formData.append('main_image_file', item.originFileObj);
					}
				})
				formData.append('opened_at', opened_at);
				formData.append('closed_at', closed_at);
				formData.append('real_stocked_at', real_stocked_at);
				formData.append('cost', cost);
				formData.append('can_buy_stock', can_buy_stock);
				formData.append('post_code', post_code);
				formData.append('address', address);
				formData.append('address_detail', address_detail);
				formData.append('expect_return_amount', expect_return_amount);
				formData.append('allocation_type', allocation_type);
				formData.append('expect_return_date', expect_return_date);
				formData.append('closed_dividend_at', closed_dividend_at);
				slider_image_files.map(item => {
					if (item.originFileObj != undefined) {
						formData.append('slider_image_files[]', item.originFileObj);
					}
				})
				formData.append('info_title', info_title);

				if(info_row) {
					info_row.forEach(item => {
						if (item != undefined) {
							formData.append('info_row[]',  '{'+ "\"title\" : \"" + item.title  + "\"," + "\"content\" : \"" + item.content + "\"}");
						}
					});
				}
				
				content_files.map(item => {
					if (item.originFileObj != undefined) {
						formData.append('content_files[]', item.originFileObj);
					}
				})
				formData.append('content', content);
	
				axios.post("https://api.kjeonse.com/api/admin/register/contest", formData,
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
					},
				})
				.then(function (response) {
					//console.log(response);
					if (response.data.success) {
						alert("저장되었습니다");
						window.location.href = '/Investment/Contest';
					}else{
						alert(response.data.msg)
					}
				})
				.catch(function (error) {
					//console.log(error);
				})
			}else{
				// 수정하기
				let formData = new FormData();
				formData.append('id', id);
				formData.append('title', title);
				formData.append('status', status);
				formData.append('description', description);
				formData.append('feature', feature);
				main_image_file.map(item => {
					if (item.originFileObj != undefined) {
						formData.append('main_image_file', item.originFileObj);
					}
				})
				formData.append('opened_at', opened_at);
				formData.append('closed_at', closed_at);
				formData.append('real_stocked_at', real_stocked_at);
				formData.append('cost', cost);
				formData.append('can_buy_stock', can_buy_stock);
				formData.append('post_code', post_code);
				formData.append('address', address);
				formData.append('address_detail', address_detail);
				formData.append('expect_return_amount', expect_return_amount);
				formData.append('allocation_type', allocation_type);
				formData.append('expect_return_date', expect_return_date);
				formData.append('closed_dividend_at', closed_dividend_at);
	
				slider_image_filesNewList.map(item => {
					if (item.originFileObj != undefined) {
						formData.append('slider_image_files[]', item.originFileObj);
					}
				})
				slider_image_filesRemoveList.map(item => {
					if (item != undefined) {
						formData.append('remove_files[]', item);
					}
				})

				formData.append('info_title', info_title);
				info_row.map(item => {
					if (item != undefined) {
						
						let keyString = "";
						let tempString = item.key+"";
						let keySplitArray = tempString.split(".");

						if(keySplitArray[0] === "N") {
							keyString = 0;
						} else {
							keyString = item.key
						}

						formData.append('info_row[]',  '{' + "\"key\" : \"" + keyString  + "\"," + "\"title\" : \"" + item.title  + "\"," + "\"content\" : \"" + item.content + "\"}");
					}
				})
	
				content_filesNewList.map(item => {
					if (item.originFileObj != undefined) {
						formData.append('content_files[]', item.originFileObj);
					}
				})
				content_filesRemoveList.map(item => {
					if (item != undefined) {
						formData.append('remove_files[]', item);
					}
				})
				
				formData.append('content', content);
	
				axios.post("https://api.kjeonse.com/api/admin/update/contest", formData,
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
					},
				})
				.then(function (response) {
					//console.log(response);
					if (response.data.success) {
						alert("수정되었습니다");
						window.location.href = '/Investment/Contest';
					}else{
						alert(response.data.msg)
					}
				})
				.catch(function (error) {
					//console.log(error);
				})
			}
		
    }

	// 콤마찍는 함수
	function comma(e) {
		let value = e.target.value;
		value = Number(value.replaceAll(',', ''));
		if(isNaN(value)) {
			return(0);
		}else {
			const formatValue = value.toLocaleString('ko-KR');
			return(formatValue);
			
		}
	}

	// 콤마 지우기 함수
	function commaOut(e) {
		const number = Number(e.toString().split(',').join(""));
		return(number)
	}

	// 에디터 모듈
	const modules = {
        toolbar: [
            //[{ 'font': [] }],
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
            ['clean']
        ],
    };

	const ingredients = info_row;
	const [removeSet, SetRemoveInfoRow] = useState([]);

	const removeHandle = (name) => {

		SetRemoveInfoRow([...removeSet, name]);
		return name;
	}

	const uploadAutoUploadLock = () => {
        return false;
    }


	// remove handle
	const contestInfoSectionRemoveHandle = (key) => {

		let tempArray = [];

		info_row.map(list => {
			if(list.key != key) {
				tempArray.push(list);
			}
		})

		setInfo_row(tempArray);

	}


	// add handle
	const [initKey, setInitKey] = useState(10000000);
	const contestInfoSectionAddHandle = () => {
		setInitKey(initKey+1);
		let emptyArray = {key: "N."+initKey, title:'', content:''};

		setInfo_row([...info_row || [], emptyArray]);
	}


	const contestInfoSectionTitleInputHandle = (e, key) => {

		let tempArray = [];

		info_row.map(list => {
			if(list.key == key) {
				tempArray.push({key: list.key, title: e.target.value, content: list.content});
			} else {
				tempArray.push(list);
			}
		})

		setInfo_row(tempArray);

	}


	const contestInfoSectionContentInputHandle = (e, key) => {

		let tempArray = [];

		info_row.map(list => {
			if(list.key == key) {
				tempArray.push({key: list.key, title: list.title, content: e.target.value});
			} else {
				tempArray.push(list);
			}
		})

		setInfo_row(tempArray);

	}


    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>투자 관리</li>
                    <li>공모 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>공모 관리</h2>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
					<div className='write-h2-wrap'>
						<h2>공모정보</h2>
						<div className='Function-btn-wrap'>
							<button className='cancel-btn' onClick={()=>{navigate(-1)}}>취소</button>
							<button className='Save-btn' onClick={()=>{save()}}>{id == "Registration" ? "저장" : "수정"}</button>
						</div>
					</div>
                    <ul className='write-list'>
                        <li>
                            <p className='labal-p'>공모명</p>
                            <div className='input-wrap'>
                                <div className='input-box'>
                                    <input 
										type="text" 
										value={title} 
										onChange={(e)=>{setTitle(e.target.value)}} 
									/>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className='labal-p'>상태</p>
                            <div className='input-wrap'>
								<Select
									defaultValue="상태 선택"
									value={status}
									style={{
										width: 120,
									}}
									onChange={statusChange}
									options={[
										{
										value: 1,
										label: '공모예정',
										},
										{
										value: 2,
										label: '공모중',
										},
										{
										value: 3,
										label: '공모마감',
										},
									]}
								/>
                            </div>
                        </li>
						<li>
                            <p className='labal-p'>주요설명</p>
                            <div className='input-wrap'>
                                <div className='input-box'>
                                    <input 
										type="text" 
										value={description} 
										onChange={(e)=>{setDescription(e.target.value)}} placeholder='주요설명을 한문장으로 입력하세요 (최대 50자)' 
									/>
                                </div>
                            </div>
                        </li>
						<li>
                            <p className='labal-p'>주요특징</p>
                            <div className='input-wrap'>
                                <div className='input-box'>
                                    <textarea 
										value={feature} 
										onChange={(e)=>{setFeature(e.target.value)}} 
										cols="30" 
										rows="5" 
										placeholder='주요특징을 입력하세요 (최대 200자)'
									>
									</textarea>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className='labal-p'>대표사진</p>
							<div className='input-wrap'>
								<Upload
									beforeUpload={uploadAutoUploadLock}
									fileList={main_image_file}
									onChange={main_image_fileOnChange}
									onRemove={main_image_fileOnRemove}
									listType="picture"
									maxCount={1}
								>
									<Button icon={<UploadOutlined />}>파일 업로드</Button>
								</Upload>
							</div>
                        </li>
                    </ul>
					<div className='write-list-wrap margin-box'>
						<ul className='write-list'>
							<li>
								<p className='labal-p'>공모일정</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
												<RangePicker 
													value={[dayjs(opened_at), dayjs(closed_at)]}
													onChange={(e,data)=>{setOpened_at(data[0]); setClosed_at(data[1])}} 
												/>
											:
												<RangePicker 
													onChange={(e,data)=>{setOpened_at(data[0]); setClosed_at(data[1])}} 
												/>
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>실제 투자일</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
												<DatePicker
													value={dayjs(real_stocked_at)}
													onChange={(e,data)=>{setReal_stocked_at(data); console.log(real_stocked_at)}} 
												/>
											:
												<DatePicker 
													onChange={(e,data)=>{setReal_stocked_at(data); console.log(real_stocked_at)}} 
												/>
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>공모액</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={cost_comma} onChange={(e)=>{setCost_comma(comma(e))}} />
										<div className='Unit'>원</div>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>공모수량</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={(cost / 1000)} className='grey-input' placeholder='' readOnly/>
										<div className='Unit'>KJT</div>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>1인 최대 금액</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={can_buy_stock_comma} onChange={(e)=>{setCan_buy_stock_comma(comma(e))}} placeholder='' />
										<div className='Unit'>원</div>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>1인 최대 수량</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={can_buy_stock / 1000} className='grey-input' placeholder='' readOnly/>
										<div className='Unit'>KJT</div>
									</div>
								</div>
							</li>
							{
								id != "Registration" ?
									<li>
										<p className='labal-p'>현재수량</p>
										<div className='input-wrap'>
											<div className='input-box'>
												<input type="text" value={now_cost} className='grey-input' placeholder='' readOnly/>
												<div className='Unit'>KJT</div>
											</div>
										</div>
									</li>
								:""
							}
						</ul>
						<ul className='write-list'>
							{/* 주소 묶음 =========================================================== */}
							<li>
								<p className='labal-p'>주소</p>
								<div className='input-wrap'>
									<div className='input-box Address'>
										<input 
											type="text" 
											value={post_code} 
											readOnly
										/>
										<button onClick={()=>{setModalState(true)}}>주소찾기</button>
										{
											modalState ?
											<div className='DaumPostcode-wrap'>
												<div className='close-wrap'>
													<p>주소찾기</p>
													<button className='close-btn' onClick={()=>{setModalState(false)}}><i className='xi-close'></i></button>
												</div>
												<DaumPostcode className='DaumPostcode' onComplete={onCompletePost}></DaumPostcode>
											</div>
											:""
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'></p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input value={address} type="text" readOnly/>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'></p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="text" value={address_detail} onChange={(e)=>{setAddress_detail(e.target.value)}} placeholder='상세주소' />
									</div>
								</div>
							</li>
							{/* ===================================================================== */}
							<li>
								<p className='labal-p'>배당 수익률(연)</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<input type="number" value={expect_return_amount} onChange={(e)=>{setExpect_return_amount(e.target.value)}} />
										<div className='Unit'>%</div>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>배당주기</p>
								<div className='input-wrap'>
									<div className='input-box'>
										<Select
											defaultValue="배당주시"
											value={allocation_type}
											style={{
												width: 120,
											}}
											onChange={allocation_typeChange}
											options={[
												{
												value: 0,
												label: '주배당',
												},
												{
												value: 1,
												label: '월배당',
												},
												{
												value: 2,
												label: '연배당',
												},
											]}
										/>
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>배당 시작일</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
											<DatePicker
											value={dayjs(expect_return_date)}
											onChange={(e,data)=>{setExpect_return_date(data)}} />
											:
											<DatePicker onChange={(e,data)=>{setExpect_return_date(data)}} />
										}
									</div>
								</div>
							</li>
							<li>
								<p className='labal-p'>배당 마감일</p>
								<div className='input-wrap'>
									<div className='input-box'>
										{
											id != "Registration" ?
												<DatePicker
													value={dayjs(closed_dividend_at)}
													hange={(e,data)=>{setClosed_dividend_at(data)}} 
												/>
											:
												<DatePicker 
													onChange={(e,data)=>{setClosed_dividend_at(data)}} 
												/>
										}
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div className='write-h2-wrap'>
						<h2>공모 이미지 업로드 <span>최소 4장 이상의 이미지를 업로드 해주세요.</span></h2>
					</div>
					<div className='margin-box'>
						<Upload
							beforeUpload={uploadAutoUploadLock}
							fileList={slider_image_files}
							onChange={slider_image_filesOnChange}
							onRemove={slider_image_filesOnRemove}
							listType="picture"
						>
							<Button icon={<UploadOutlined />}>파일 업로드</Button>
						</Upload>
					</div>
					<div className='write-h2-wrap'>
						<h2>기본정보</h2>
					</div>
					<ul className='write-list'>
                        <li>
                            <p className='labal-p'>대제목</p>
                            <div className='input-wrap'>
                                <div className='input-box'>
                                    <input type="text" value={info_title} onChange={(e)=>{setInfo_title(e.target.value)}} />
                                </div>
                            </div>
                        </li>
                    </ul>
					<div className='Add-field-wrap margin-box'>
					
						<ContestInfoSection>
							
							{
								info_row ?
									info_row.map(list => {

										return (

											<ContestInfoLine>

												<ContestInfoTitle>
													소제목
												</ContestInfoTitle>
												
												<ContestInfoContentInput 
													value={list.title} 
													onChange={(e) => contestInfoSectionTitleInputHandle(e, list.key)}
												/>

												<ContestInfoTitle>
													내용
												</ContestInfoTitle>

												<ContestInfoContentInput 
													value={list.content} 
													onChange={(e) => contestInfoSectionContentInputHandle(e, list.key)}
												/>

												<ContestInfoDeleteButton onClick={() => contestInfoSectionRemoveHandle(list.key)}>
													삭제
												</ContestInfoDeleteButton>

											</ContestInfoLine>

										)

									})
									:
									''
							}
						
							<ContestInfoAddButton onClick={contestInfoSectionAddHandle}>
								+ Add field
							</ContestInfoAddButton>

						</ContestInfoSection>

					</div>
					<div className='write-h2-wrap'>
						<h2>상세정보</h2>
					</div>
					<ul className='write-list'>
                        <li>
                            <p className='labal-p'>내용</p>
                            <div className='input-wrap'>
								<div className='Editor-wrap'>
									<ReactQuill
                                        // {...rest}
                                        value={content || ""}
                                        theme="snow" 
                                        modules={modules}
                                        // formats={formats}
                                        onChange={(e)=>{setContent(e)}}
                                    ></ReactQuill>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className='labal-p'>파일첨부</p>
							<div className='input-wrap'>
							<Upload
								beforeUpload={uploadAutoUploadLock}
								fileList={content_files}
								onChange={content_filesOnChange}
								onRemove={content_filesOnRemove}
							>
								<Button icon={<UploadOutlined />}>파일 업로드</Button>
							</Upload>
							</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ContestRg;


const ContestInfoSection = styled.div`
	width: 100%;
	height: 100%;
`;

const ContestInfoLine = styled.div`
	width:100%;
	height:40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 15px;
`;

const ContestInfoTitle = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	margin-right: 5px;
	font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
`

const ContestInfoContentInput = styled.input`
	width: calc((100% - 120px) / 2);
	height: 40px;
	margin-right: 15px;
	padding-left: 15px;
	border-radius: 2px;
    border: solid 1px #d9d9d9;
`;

const ContestInfoDeleteButton = styled.button`
	width: 50px;
	height: 35px;
	background-color: red;
	color: white;
	font-weight: bold;
	border-radius: 10px;
`;

const ContestInfoAddButton = styled.button`
	width: 100%;
	height: 40px;
	border: 1px dotted gray;
	background-color: gray;
	color: white;
	font-weight: bold;
`;