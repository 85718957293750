import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { UploadOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Upload, Switch, DatePicker, Table } from 'antd';
import { Form, Input, Space } from 'antd';
import { Select } from 'antd';

// 에디터
import  {  useQuill  }  from  'react-quilljs' ; 
import  'quill/dist/quill.snow.css' ;


function DividendDt() {
	let navigate = useNavigate();
	let { id } = useParams();

	// 공모 이름
	let [title ,setTitle] = useState("");
	// 공모수량 
	let [cost, setCost] = useState("");
	// 투자자
	let [totalStockedPeople, setTotalStockedPeople] = useState("");
	// 배당시작일
	let [start_dividend_date, setStart_dividend_date] = useState("");
	// 배당마감일
	let [end_dividend_date, setEnd_dividend_date] = useState("");
	// 누적배당금
	let [sumDividendStockAmount, setSumDividendStockAmount] = useState("");
	// 누적배당회차
	let [sumDividendCount, setSumDividendCount] = useState("");
	// 배당회차 리스트 배열
	let [sumDividendCount_list, setSumDividendCount_list] = useState([]);
	useEffect(()=>{
		let sumDividendCount_list = [];
		for (let i = 0; i < sumDividendCount + 1; i++) {
			if (i == 0) {
				sumDividendCount_list.push({value: i , label: '전체'})
			}else{
				sumDividendCount_list.push({value: i , label: i + '회차'})
			}
		}
		setSumDividendCount_list(sumDividendCount_list);
	},[sumDividendCount])

	// 총배당회차
	let [allocation, setAllocation] = useState("");
	// 남은날짜 구하기
	function dday(s,e) {
		let start = new Date(s);
		let end = new Date(e)
		let gap = end.getTime() - start.getTime();
		let result = Math.ceil(gap / (1000 * 60 * 60 * 24));
		return result
	}
	// 투자자 목록
	let [stock_list, setStock_list] = useState([]);


	useEffect(()=>{
		axios.post("https://api.kjeonse.com/api/admin/detail/dividend", {
			id: id,
		},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data);
			let detail = response.data.data.detail[0];
			setTitle(detail.title);
			setCost(detail.cost / 1000);
			setTotalStockedPeople(detail.totalStockedPeople);
			setStart_dividend_date(detail.start_dividend_date);
			setEnd_dividend_date(detail.end_dividend_date);
			setSumDividendStockAmount(detail.sumDividendStockAmount);
			setSumDividendCount(detail.sumDividendCount);

			let totalCount = null;
			if (detail.allocation_type == 0) {
				totalCount = Math.floor(dday(detail.start_dividend_date, detail.end_dividend_date) / 7);
			}else if (detail.allocation_type == 1) {
				totalCount = Math.floor(dday(detail.start_dividend_date, detail.end_dividend_date) / 31);
			}else if (detail.allocation_type == 2) {
				totalCount = Math.floor(dday(detail.start_dividend_date, detail.end_dividend_date) / 365);
			}
			setAllocation(totalCount);

			let stock_list = response.data.data.stock_list;
			// console.log(stock_list);
			setStock_list(stock_list);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])

	// 배당하기
	let [amount_comma,setAmount_comma] = useState("");
	let [amount, setAmount] = useState("");
	useEffect(()=>{setAmount(commaOut(amount_comma))},[amount_comma]);
	function dividend() {
		if (window.confirm("정말 배당 하시겠습니까?")) {

			axios.post("https://api.kjeonse.com/api/admin/register/dividend", {
				id: id,
				amount: amount,
			},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
				},
			})
			.then(function (response) {
				alert("배당하였습니다");
				window.location.reload();
			})
			.catch(function (error) {
				console.log(error);
			})

		} else {

		alert("취소합니다.");

		}
		
	}

	// 배당 상세 - 검색
	// 상태 Select
	let[round, setRound] = useState(0);
	const handleChange = (value) => {
		console.log(`selected ${value}`);
		setRound(value);
	};
	// 검색하기
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/detail/dividend", {
			id:id,
			time:round,
			keyword:value,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			setStock_list(response.data.data.stock_list);
			console.log(response.data.data.stock_list);
		})
		.catch(function (error) {
			console.log(error);
		})
		if (round == 0) {
			setColumns_change(0);
		}else{
			setColumns_change(1);
		}
    }


	// 배당자 삭제
    function delete_item(user_id , contest_id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/dividend/user", {
                user_id : user_id,
				contest_id : contest_id
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.replace()
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }


	// 전체 회차
	let [columns_change, setColumns_change] = useState(0);
	let columns = [];
	if (columns_change == 0) {
		columns = [
			{
				title: '투자자명',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: '전화번호',
				dataIndex: 'phone',
				key: 'phone',
			},
			{
				title: '공모수량(KJT)',
				key: 'totalSumStockTokenAmount',
				render: (_, record) => (record.totalSumStockTokenAmount / 1000),
			},
			{
				title: '일반 투자금(원)',
				key: 'totalSumStockAmount',
				render: (_, record) => (Number(record.totalSumStockAmount).toLocaleString()),
			},
			{
				title: '전세 투자금(원)',
				key: 'totalSumStockEstateAmount',
				render: (_, record) => (Number(record.totalSumStockEstateAmount).toLocaleString()),
			},
			{
				title: '누적 배당금(원)',
				key: 'totalSumDividendAmount',
				render: (_, record) => (Number(record.totalSumDividendAmount).toLocaleString()),
			},
			{
				title: '투자일짜',
				key: 'created_at',
				render: (_, record) => (record.created_at.substr(0, 10)),
			},
			{
				title: '관리',
				key: 'action',
				render: (_, record) => (
					<Space size="middle" className='table-btn-wrap'>
						<button onClick={()=>{delete_item(record.user_id , id);}}>삭제</button>
					</Space>
				),
			},
		];
	}else{
		columns = [
			{
				title: '투자자명',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: '전화번호',
				dataIndex: 'phone',
				key: 'phone',
			},
			{
				title: '공모수량(KJT)',
				key: 'totalSumStockTokenAmount',
				render: (_, record) => (record.totalSumStockTokenAmount / 1000),
			},
			{
				title: '일반 투자금(원)',
				key: 'totalSumStockAmount',
				render: (_, record) => (Number(record.totalSumStockAmount).toLocaleString()),
			},
			{
				title: '전세 투자금(원)',
				key: 'totalSumStockEstateAmount',
				render: (_, record) => (Number(record.totalSumStockEstateAmount).toLocaleString()),
			},
			{
				title: '배당금(원)',
				key: 'userDividendCountSelectAmount',
				dataIndex: 'userDividendCountSelectAmount',
			},
			{
				title: '누적 배당금(원)',
				key: 'totalSumDividendAmount',
				render: (_, record) => (Number(record.totalSumDividendAmount).toLocaleString()),
			},
			{
				title: '투자일짜',
				key: 'created_at',
				render: (_, record) => (record.created_at.substr(0, 10)),
			},
			{
				title: '관리',
				key: 'action',
				render: (_, record) => (
					<Space size="middle" className='table-btn-wrap'>
						<button onClick={()=>{delete_item(record.user_id , id);}}>삭제</button>
					</Space>
				),
			},
		];
	}

	// 콤마찍는 함수
	function comma(e) {
		let value = e.target.value;
		value = Number(value.replaceAll(',', ''));
		if(isNaN(value)) {
			return(0);
		}else {
			const formatValue = value.toLocaleString('ko-KR');
			return(formatValue);
			
		}
	}
	// 콤마 지우기 함수
	function commaOut(e) {
		const number = Number(e.toString().split(',').join(""));
		return(number)
	}

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>투자 관리</li>
                    <li>배당 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>배당 관리</h2>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
					<div className='write-h2-wrap'>
						<h2>공모정보</h2>
						<div className='Function-btn-wrap'>
							<button className='cancel-btn' onClick={()=>{navigate("/Investment/Dividend")}}>뒤로가기</button>
						</div>
					</div>
					<div className='contest-Information-wrap margin-box'>
						<h2>{title}</h2>
						<ul className='Information-list'>
							<li>
								<p className='labal-p'>공모수량(KJT) :</p>
								<p className='answer-p'>{cost}</p>
							</li>
							<li>
								<p className='labal-p'>투자자 :</p>
								<p className='answer-p'>{totalStockedPeople}</p>
							</li>
							<li>
								<p className='labal-p'>배당 시작일:</p>
								<p className='answer-p'>{start_dividend_date}</p>
							</li>
							<li>
								<p className='labal-p'>배당 마감일 :</p>
								<p className='answer-p'>{end_dividend_date}</p>
							</li>
							<li>
								<p className='labal-p'>누적 배당금(원) :</p>
								<p className='answer-p'>{sumDividendStockAmount}</p>
							</li>
							<li>
								<p className='labal-p'>누적 배당 회 차 :</p>
								<p className='answer-p'>{sumDividendCount}</p>
							</li>
							<li>
								<p className='labal-p'>총 배당 회 차 :</p>
								<p className='answer-p'>{allocation}</p>
							</li>
						</ul>
					</div>
					<div className='write-h2-wrap'>
						<h2>배당하기 <span>배당된 금액은 취소할 수 없으니 신중하게 입력해 주세요.</span></h2>
					</div>
					<ul className='write-list margin-box'>
                        <li>
                            <p className='labal-p'>배당금</p>
                            <div className='input-wrap dividend-input-wrap'>
                                <div className='input-box'>
                                    <input type="text" value={amount_comma} onChange={(e)=>{setAmount_comma(comma(e))}} />
									<div className='Unit'>원</div>
                                </div>
								<button className='dividend-btn' onClick={()=>{dividend()}}>배당하기</button>
                            </div>
                        </li>
                    </ul>
					<div className='write-h2-wrap'>
						<h2>투자자 목록</h2>
						<div className='Function-btn-wrap'>
							<Select
								defaultValue={0}
								style={{
									width: 120,
								}}
								onChange={handleChange}
								options={sumDividendCount_list}
							/>
							<Search
								placeholder="투자자명을 입력하세요"
								onSearch={onSearch}
								style={{
									width: 200,
								}}
							/>
							<button onClick={()=>{navigate("/Investment/Add_investor/" + id)}} className='investor-Add-btn'>투자자 추가</button>
						</div>
					</div>
					<div className='table-wrap'>
						<Table columns={columns} dataSource={stock_list} rowKey={(render)=> render.user_id} />
					</div>
                </div>
            </div>
        </div>
    )
}

export default DividendDt;