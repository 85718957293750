import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Space, Table, Tag, Input } from 'antd';

function Withdrawal_Request() {
    let navigate = useNavigate();

    // 표 리스트
    let [data, setData] = useState([]);

    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/withdraw", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data);
            setData(response.data.data.list);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])
    console.log();


    // 검색
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/withdraw", {
            keyword:value,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.list);
            setData(response.data.data.list);
		})
		.catch(function (error) {
			console.log(error);
		})
    }

    // 삭제하기
    function delete_item(id) {
        console.log(id);
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/withdraw", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                if (response.data.success) {
                    alert("삭제되었습니다");
                    window.location.reload();
                }else{
                    alert(response.data.msg);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }

    // 승인하기
    function approval_item(id) {
        console.log(id);
        if (window.confirm("정말 승인합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/update/withdraw", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                if (response.data.success) {
                    alert("승인되었습니다");
                    window.location.reload();
                }else{
                    alert(response.data.msg);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }


    // 표 틀
    const columns = [
        {
            title: '회원명',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '전화번호',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '은행',
            dataIndex: 'bank_name',
            key: 'bank_name',
        },
        {
            title: '계좌번호',
            key: 'bank_account_number',
            dataIndex: 'bank_account_number',
        },
		{
            title: '출금액(원)',
            key: 'withDraw_amount',
            dataIndex: 'withDraw_amount',
        },
        {
            title: '출금 요청 날짜',
            key: 'created_at',
            dataIndex: 'created_at',
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
                    {
                        !record.completed_at ?
                            <button onClick={()=>{approval_item(record.id)}}>승인</button>
                        :""
                    }
                    <button onClick={()=>{delete_item(record.id)}}>삭제</button>
                    {console.log(record)}
                </Space>
            ),
        },
    ];


	
    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>입출금 관리</li>
                    <li>출금 요청 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>출금 요청 관리</h2>
                    <div className='Function-btn-wrap'>
						<Search
							placeholder="검색어를 입력하세요"
							onSearch={onSearch}
							style={{
								width: 200,
							}}
						/>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <div className='table-wrap'>
                        <Table columns={columns} dataSource={data} rowKey={(render)=> render.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Withdrawal_Request;