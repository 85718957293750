import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Space, Table, Tag, Input } from 'antd';

function Contest() {
    let navigate = useNavigate();

    // 표리스트
    let [data, setData] = useState([]);

    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/dividend", {},
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
            },
        })
        .then(function (response) {
            console.log(response.data.data.list);
            setData(response.data.data.list);
        })
        .catch(function (error) {
            console.log(error);
        })
    },[])

    // 검색
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/dividend", {
            keyword:value,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.list);
            setData(response.data.data.list);
		})
		.catch(function (error) {
			console.log(error);
		})
    }

    // 삭제하기
    function delete_item(id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/dividend", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.replace()
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }

    // 표 틀
    const columns = [
        {
            title: '공모명',
            dataIndex: 'sortation',
            key: 'sortation',
			render: (_, record) => <button className='blue-btn' onClick={()=>{navigate("/Investment/DividendDt/" + record.id)}}>{record.title}</button> ,
        },
        {
            title: '투자자',
            key: 'totalStockedPeople',
            dataIndex: 'totalStockedPeople',
        },
        {
            title: '공모수량(KJT)',
            key: 'cost',
            render: (_, record) => (record.cost / 1000),
        },
        {
            title: '누적 배당 회 차',
            key: 'sumDividendCount',
            dataIndex: 'sumDividendCount',
        },
		{
            title: '누적 배당 수량(KJT)',
            key: 'number',
            render: (_, record) => (record.sumDividendStockAmount / 1000),
        },
		{
            title: '배당 시작일',
            key: 'start_dividend_date',
            dataIndex: 'start_dividend_date',
        },
		{
            title: '배당 마감일',
            key: 'end_dividend_date',
            dataIndex: 'end_dividend_date',
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
					<button onClick={()=>{delete_item(record.id)}}>삭제</button>
                </Space>
            ),
        },
    ];
	
    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>투자 관리</li>
                    <li>배당 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>배당 관리</h2>
                    <div className='Function-btn-wrap'>
						<Search
							placeholder="검색어를 입력하세요"
							onSearch={onSearch}
							style={{
								width: 200,
							}}
						/>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <div className='table-wrap'>
                        <Table columns={columns} dataSource={data} rowKey={(render)=> render.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contest;