import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

import * as XLSX from 'xlsx';

// API
import axios from 'axios';

// 엔트디자인
import { Space, Table, Tag, Input } from 'antd';

function Membership() {
    let navigate = useNavigate();

    // 표리스트
    let [list, setList] = useState([]);
    // 설문1
    let [survey_1, setSurvey_1] = useState([]);
    // 설문2
    let [survey_2, setSurvey_2] = useState([]);
    // 설문3
    let [survey_3, setSurvey_3] = useState([]);
    // id 같은 배열 합치기
    let [combine_list , setCombine_list] = useState([])


    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/user", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data);
            setList(response.data.data.list);
            setSurvey_1(response.data.data.survey_1);
            setSurvey_2(response.data.data.survey_2);
            setSurvey_3(response.data.data.survey_3);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])

    // 검색
	const { Search } = Input;
    function onSearch(value) {
        axios.post("https://api.kjeonse.com/api/admin/search/user", {
            keyword:value,
        },
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data);
            setList(response.data.data.list);
            setSurvey_1(response.data.data.survey_1);
            setSurvey_2(response.data.data.survey_2);
            setSurvey_3(response.data.data.survey_3);
		})
		.catch(function (error) {
			console.log(error);
		})
    }

    // 삭제하기
    function delete_item(id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/update/user/leaved", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.replace("/Membership/Membership")
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }

    /*
        { 
            id: id, 
            email: email,
            birth: birth,
            name: name,
            phone: phone,
            survey_1: "survey1_item, survey1_item, survey1_item",
            survey_2: "survey2_item, survey2_item, survey2_item",
            survey_3: "survey3_item, survey3_item, survey3_item",
        }
    
    */

    /*
        survey_1, 2, 3 id 값과 list.map(item) => item.id 값이 동일할 때,
        survey_x 키 값에 배열로 추가한다.
    
    */
    // 리스트배열 설문리스트배열 합치기
    list.map(item => {

        let survey_1_array = [];
        let survey_2_array = [];
        let survey_3_array = [];

        survey_1.forEach(surveyItem => {
            if(item.id == surveyItem.id) {
                survey_1_array.push(surveyItem.title);
            }
        });

        survey_2.forEach(surveyItem => {
            if(item.id == surveyItem.id) {
                survey_2_array.push(surveyItem.title);
            }
        });

        survey_3.forEach(surveyItem => {
            if(item.id == surveyItem.id) {
                survey_3_array.push(surveyItem.title);
            }
        });

        item.survey_1 = survey_1_array.join();
        item.survey_2 = survey_2_array.join();
        item.survey_3 = survey_3_array.join();
    });


    // 표 틀
    const columns = [
        {
            title: '구분',
            dataIndex: 'sortation',
            key: 'sortation',
            render: (_, record) => (
                record.type== 5 ?
                <>비회원</>
                : 
                <>회원</>
            ),
        },
        {
            title: '회원명',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '이메일',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '생년월일',
            key: 'birth',
            dataIndex: 'birth',
        },
		{
            title: '전화번호',
            key: 'phone',
            dataIndex: 'phone',
        },
		{
            title: '설문1',
            key: 'survey_1',
            dataIndex: 'survey_1',
        },
		{
            title: '설문2',
            key: 'survey_2',
            dataIndex: 'survey_2',
        },
		{
            title: '설문3',
            key: 'survey_3',
            dataIndex: 'survey_3',
        },
		{
            title: '가입일',
            key: 'created_at',
            render: (_, record) => (
                record.created_at.substr(0, 10)
            ),
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
                    <button onClick={()=>{delete_item(record.id)}}>탈퇴</button>
                </Space>
            ),
        },
    ];



	const downloadExcel = useCallback(() => {

        const excelHandler = {
            getExcelFileName: () => {
                return '회원관리.xlsx';
            },
            getSheetName: () => {
                return '리스트';
            },
            getExcelData: () => {
                return list;
            },
            getWorksheet: () => {
                return XLSX.utils.json_to_sheet(excelHandler.getExcelData());
            }
        };
        
        let Heading = [['idx', '회원분류', '성명', '이메일', '생년월일', '전화번호', '개인정보 동의여부', '마케팅 동의여부', '가입일', '탈퇴일', '설문1', '설문2', '설문3']];

        // const data = excelHandler.getWorksheet();
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, data, excelHandler.getSheetName());
        // XLSX.writeFile(workbook, excelHandler.getExcelFileName());

        const wb = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, Heading);

        XLSX.utils.sheet_add_json(ws, list, { origin: 'A2', skipHeader: true});
        XLSX.utils.book_append_sheet(wb, ws, 'list');
        XLSX.writeFile(wb, excelHandler.getExcelFileName());

    }, [list]);
	
    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>회원 관리</li>
                    <li>회원 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>회원 관리</h2>
                    <div className='Function-btn-wrap'>
						<Search
							placeholder="검색어를 입력하세요"
							onSearch={onSearch}
							style={{
								width: 200,
							}}
						/>
                        <button 
                            className='Excel-Down-btn'
                            onClick={downloadExcel}
                        >
                            <i className='xi-file-download-o'></i>
                            엑셀
                        </button>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <div className='table-wrap'>
                        <Table columns={columns}
                        dataSource={list}
                        rowKey={(render)=> render.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Membership;