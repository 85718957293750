import './index.css';
import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import axios from 'axios';

// 해드푸터
import Mould from './components/Mould';

// 페이지
// 로그인 페이지====================
import Login from './pages/Login';
// 홈페이지 관리====================
// 팝업 관리
import PopUp from "./pages/Homepage/PopUp";
import PopUpRg from "./pages/Homepage/PopUpRg";
// 배너 관리
import Banner from "./pages/Homepage/Banner";
import BannerRg from "./pages/Homepage/BannerRg";
// 제휴사 관리
import Company from "./pages/Homepage/Company";
import CompanyRg from "./pages/Homepage/CompanyRg";
// 소개 이미지 관리
import IntroductionImg from "./pages/Homepage/IntroductionImg";
// 이용안내
import User_information from "./pages/Homepage/User_information";
import User_informationRg from "./pages/Homepage/User_informationRg";
// k-전세 소식
import News from "./pages/Homepage/News";
import NewsRg from './pages/Homepage/NewsRg';
// 자주하는 질문
import Question from "./pages/Homepage/Question";
import QuestionRg from "./pages/Homepage/QuestionRg";

// 회원관리----------------------------
// 회원 관리
import Membership from "./pages/Membership/Membership";
// 탈퇴회원 관리
import Withdrawal from "./pages/Membership/Withdrawal";

// 투자 관리 ================================
// 공모 관리
import Contest from "./pages/Investment/Contest";
import ContestRg from "./pages/Investment/ContestRg";
// 배당 관리
import Dividend from "./pages/Investment/Dividend";
import DividendDt from "./pages/Investment/DividendDt";
// 배당 관리 투자자 추가
import Add_investor from "./pages/Investment/Add_investor";

// 전세 관리 ==============================================
// 전세관리
import Charter from "./pages/Charter/Charter";
import CharterRg from "./pages/Charter/CharterRg";
// 전세 관리자 공지
import Charter_Announcement from "./pages/Charter/Charter_Announcement";
// 전세 임대차 계약 관리
import Charter_Contract from "./pages/Charter/Charter_Contract";
// 전세 시설 관리 / 문의
import Charter_qna from "./pages/Charter/Charter_qna";

// 입출금 관리 ==============================================
// 회원 자산 관리
import MemberAssets from "./pages/Deposit_And_Withdrawa/MemberAssets";
// 출금 요청 관리
import Withdrawal_Request from "./pages/Deposit_And_Withdrawa/Withdrawal_Request";
// 입금 내역 관리
import Deposit_details from "./pages/Deposit_And_Withdrawa/Deposit_details";


// 데이터
import { data } from './date';


let obj = { name : 'kim' };
localStorage.setItem( 'data', JSON.stringify(obj) );
let josnObj = JSON.parse(localStorage.getItem('data'));
console.log(josnObj);

function App() {
  let [shose ,setShose] = useState(data);
  let navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('watched')) == '') {
      localStorage.setItem( 'watched' , JSON.stringify( [] ) )
    }
  }, [])

  return (
    <div className="App">
      {/* header */}
      {/* <Header></Header> */}

      {/* body */}
      <Routes>
        {/* 로그인페이지 */}
        <Route path='/' element={<Login></Login>}></Route>
        
        {/* 관리자페이지 틀 */}
        <Route path='/' element={<Mould/>}>
          {/* 홈페이지 관리 ==================================================================== */}
          {/* 팝업관리 */}
          <Route path='/Homepage/PopUp' element={<PopUp></PopUp>}></Route>
          <Route path='/Homepage/PopUpRg/:id' element={<PopUpRg></PopUpRg>}></Route>
          {/* 배너 관리 */}
          <Route path='/Homepage/Banner' element={<Banner></Banner>}></Route>
          <Route path='/Homepage/BannerRg/:id' element={<BannerRg></BannerRg>}></Route>
          {/* 제휴사 관리 */}
          <Route path='/Homepage/Company' element={<Company></Company>}></Route>
          <Route path='/Homepage/CompanyRg/:id' element={<CompanyRg></CompanyRg>}></Route>
          {/* 소개 이미지 관리 */}
          <Route path='/Homepage/IntroductionImg' element={<IntroductionImg></IntroductionImg>}></Route>
          {/* 이용안내 */}
          <Route path='/Homepage/User_information' element={<User_information></User_information>}></Route>
          <Route path='/Homepage/User_informationRg/:id' element={<User_informationRg></User_informationRg>}></Route>
          {/* k-전세소식 */}
          <Route path='/Homepage/News' element={<News></News>}></Route>
          <Route path='/Homepage/NewsRg/:id' element={<NewsRg></NewsRg>}></Route>
          {/* 자주하는 질문 */}
          <Route path='/Homepage/Question' element={<Question></Question>}></Route>
          <Route path='/Homepage/QuestionRg/:id' element={<QuestionRg></QuestionRg>}></Route>


          {/* 회원 관리 ======================================================================== */}
          {/* 회원관리 */}
          <Route path='/Membership/Membership' element={<Membership></Membership>}></Route>
          {/* 탈퇴회원 관리 */}
          <Route path='/Membership/Withdrawal' element={<Withdrawal></Withdrawal>}></Route>


          {/* 투자 관리 ========================================================================== */}
          {/* 공모 관리 */}
          <Route path='/Investment/Contest' element={<Contest></Contest>}></Route>
          <Route path='/Investment/ContestRg/:id' element={<ContestRg></ContestRg>}></Route>
          {/* 배당 관리 */}
          <Route path='/Investment/Dividend' element={<Dividend></Dividend>}></Route>
          <Route path='/Investment/DividendDt/:id' element={<DividendDt></DividendDt>}></Route>
          {/* 배당 관리 투자자추가 */}
          <Route path='/Investment/Add_investor/:id' element={<Add_investor></Add_investor>}></Route>


          {/* 전세 관리 =============================================================================*/}
          {/* 전세관리 */}
          <Route path='/Charter/Charter' element={<Charter></Charter>}></Route>
          <Route path='/Charter/CharterRg/:id' element={<CharterRg></CharterRg>}></Route>
          {/* 전세 관리자 공지 */}
          <Route path='/Charter/Charter_Announcement/:id/:eid' element={<Charter_Announcement></Charter_Announcement>}></Route>
          {/* 전세 임대차 계약 관리 */}
          <Route path='/Charter/Charter_Contract/:id/:eid' element={<Charter_Contract></Charter_Contract>}></Route>
          {/* 전세 시설 관리 / 문의 */}
          <Route path='/Charter/Charter_qna/:id/:eid' element={<Charter_qna></Charter_qna>}></Route>

          {/* 입출금 관리 ======================================================================== */}
          {/* 회원 자산 관리 */}
          <Route path='/Deposit_And_Withdrawa/MemberAssets' element={<MemberAssets></MemberAssets>}></Route>
          {/* 출금 요청 관리 */}
          <Route path='/Deposit_And_Withdrawa/Withdrawal_Request' element={<Withdrawal_Request></Withdrawal_Request>}></Route>
          {/* 입금 내역 관리 */}
          <Route path='/Deposit_And_Withdrawa/Deposit_details' element={<Deposit_details></Deposit_details>}></Route>
        </Route>

        {/* 404 */}
        <Route path='*' element={<div>404</div>}></Route>
      </Routes>
    </div>
  );
}

export default App;
