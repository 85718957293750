import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';


// 앤트디자인
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd'
import { Menu } from 'antd';



function SMB() {
    let navigate = useNavigate();

    function getItem(label,key,icon,children,type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        }
    };

    const items = [
        getItem('홈페이지 관리', 'sub1', <i className='xi-home-o'></i>, [
            getItem('팝업 관리', '/Homepage/PopUp'),
            getItem('베너 관리', '/Homepage/Banner'),
            getItem('제휴사 관리', '/Homepage/Company'),
            getItem('소개 이미지 관리', '/Homepage/IntroductionImg'),
            getItem('이용안내', '/Homepage/User_information'),
            getItem('K-전세 소식', '/Homepage/News'),
            getItem('자주하는 질문', '/Homepage/Question'),
        ]),
        getItem('회원 관리', 'sub2', <i className='xi-user-o'></i>, [
            getItem('회원 관리', '/Membership/Membership'),
            getItem('탈퇴회원 관리', '/Membership/Withdrawal'),
        ]),
        getItem('투자 관리', 'sub3', <i className='xi-bank'></i>, [
            getItem('공모 관리', '/Investment/Contest'),
            getItem('배당 관리', '/Investment/Dividend'),
        ]),
        getItem('전세 관리', 'sub4', <i className='xi-wallet'></i>, [
            getItem('전세 관리', '/Charter/Charter'),
        ]),
        getItem('입출금 관리', 'sub5', <i className='xi-money'></i>, [
            getItem('회원 자산 관리', '/Deposit_And_Withdrawa/MemberAssets'),
            getItem('출금 요청 관리', '/Deposit_And_Withdrawa/Withdrawal_Request'),
            getItem('입금 내역 관리', '/Deposit_And_Withdrawa/Deposit_details'),
        ]),
    ];

    const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5'];

    const [openKeys, setOpenKeys] = useState(['sub1']);

    //
    const onOpenChange = (keys) => {

        console.log(keys);

        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    //
    const navigateChange = (e) => {
        console.log(e.key);
        navigate(e.key);
    }

    // 로그아웃
    function logout() {
        localStorage.removeItem('k-Jeonse-admin-token');
        navigate("/");
    }

    //
    const moveToPage = () => {
        window.location.href = '/Homepage/PopUp';
    }

    return (
        <div className={"SMB"}>
            <div className='hd-wrap'>
                <div className='user-wrap'>
                    <i className='xi-profile'></i>
                    <p className='user-name'>K전세 관리자</p>
                </div>
            </div>
            <div className='SMB-wrap'>
                <div className='logo-wrap' onClick={moveToPage}>
                    <img src={require("../images/W-LOGO.png")} alt="" />
                </div>
                <div className='menu-wrap'>
                    <Menu
                        mode="inline"
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        onClick={(e) => navigateChange(e)}
                        style={{ width: 208 }}
                        items={items}
                        theme="dark"
                    />
                </div>
                <div className='SMB-bt-wrap'>
                    <p>
                        COPYRIGHT 2022 K전세 <br />
                        ALL RIGHT RESERVED. 
                    </p>
                    <button className='Sign-out-bt' onClick={()=>{logout()}}>
                        <i className='xi-log-out'></i>
                        <span>로그아웃</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SMB;