import { Outlet } from 'react-router-dom';
import SMB from './SMB';
import Footer from './Footer';

function Mould() {
    return (
        <>
            <SMB/>
			<Outlet></Outlet>
        </>
    )
}

export default Mould;