import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Space, Table, Tag } from 'antd';

function Question() {
    let navigate = useNavigate();

    // 표리스트
    let [data, setData] = useState([]);
    
    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/faq", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response.data.data.list);
            setData(response.data.data.list);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])
    console.log();

    // 삭제하기
    function delete_item(id) {
        if (window.confirm("정말 삭제합니까?")) {
            axios.post("https://api.kjeonse.com/api/admin/delete/faq", {
                id : id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                alert("삭제되었습니다");
                window.location.replace("/Homepage/Question")
            })
            .catch(function (error) {
                console.log(error);
            })
        } else {
            alert("취소합니다.");
        }
    }

    // 표 틀
    const columns = [
        {
            title: '구분',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '작성일',
            key: 'created_at',
            render: (_, record) => (
                record.created_at.substr(0, 16)
            ),
        },
        {
            title: '작성자',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '관리',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className='table-btn-wrap'>
                    <button onClick={()=>{navigate("/Homepage/QuestionRg/" + record.id)}}>수정</button>
                    <button onClick={()=>{delete_item(record.id)}}>삭제</button>
                </Space>
            ),
        },
    ];

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>홈페이지 관리</li>
                    <li>자주하는 질문</li>
                </ul>
                <div className='title-wrap'>
                    <h2>자주하는 질문</h2>
                    <div className='Function-btn-wrap'>
                        <button className='Registration-btn' onClick={()=>{navigate("/Homepage/QuestionRg/" + "Registration")}}>
                            <i className='xi-plus-thin'></i>
                            등록
                        </button>
                    </div>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <div className='table-wrap'>
                        <Table columns={columns} dataSource={data} rowKey={(render)=> render.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Question;