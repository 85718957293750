import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { Select } from 'antd';

function QuestionRg() {
	let navigate = useNavigate();
    let { id } = useParams();
    
    // 제목
    let [title, setTitle] = useState("");
    // 내용
    let [content, setContent] = useState("");

    // 구분
    let [category_id, setCategory_id] = useState("");
    const handleChange = (value) => {
		setCategory_id(value);
	};

    // 수정하기면 기존에 있던 내용뿌리기
    useEffect(()=>{
        if (id != "Registration") {
            axios.post("https://api.kjeonse.com/api/admin/detail/faq", {
                id: id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                setTitle(response.data.data.detail.title);
                setContent(response.data.data.detail.content);
                if (response.data.data.detail.category == "이용안내") {
                    setCategory_id(1)
                }else if (response.data.data.detail.category == "K-전세") {
                    setCategory_id(2)
                }else if (response.data.data.detail.category == "투자") {
                    setCategory_id(3)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    },[])

    console.log(category_id);

    function save() {
        if (id == "Registration") {
            // 등록하기
            let formData = new FormData();
            formData.append('category_id', category_id);
            formData.append('title', title);
            formData.append('content', content);
            axios.post("https://api.kjeonse.com/api/admin/register/faq", formData,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                if (response.data.success) {
                    alert("저장되었습니다");
                    navigate(-1)
                }else{
                    alert(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }else{
            // 수정하기
            let formData = new FormData();
            formData.append('category_id', category_id);
            formData.append('title', title);
            formData.append('content', content);
            formData.append('id', id);
            axios.post("https://api.kjeonse.com/api/admin/update/faq", formData,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                if (response.data.success) {
                    alert("수정되었습니다");
                    navigate(-1)
                }else{
                    alert(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }
	
	

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>홈페이지 관리</li>
                    <li>자주하는 질문</li>
                </ul>
                <div className='title-wrap'>
                    <h2>자주하는 질문</h2>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <ul className='write-list'>
						<li>
                            <p className='labal-p'>구분</p>
                            <div className='input-wrap'>
								<Select
									defaultValue="구분"
									style={{
										width: 120,
									}}
									onChange={handleChange}
									options={[
										{
										value: 1,
										label: '이용안내',
										},
										{
										value: 2,
										label: 'K-전세',
										},
										{
										value: 3,
										label: '투자',
										},
									]}
								/>
                            </div>
                        </li>
                        <li>
                            <p className='labal-p'>제목</p>
                            <div className='input-wrap'>
                                <div className='input-box'>
                                    <input type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} placeholder='제목을 입력하세요.' />
                                </div>
                            </div>
                        </li>
						<li>
                            <p className='labal-p'>내용</p>
                            <div className='input-wrap'>
                                <div className='input-box'>
                                    <textarea value={content} onChange={(e)=>{setContent(e.target.value)}} cols="30" rows="10" 
									placeholder='내용을 입력하세요'
									></textarea>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className='Save-btn-wrap'>
                        <button className='cancel-btn' onClick={()=>{navigate("/Homepage/Question")}}>취소</button>
                        <button className='Save-btn' onClick={()=>{save()}}>저장</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuestionRg;