import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

function IntroductionImg() {
    let navigate = useNavigate();

    // 소개 이미지 데이터
    let [data, setData] = useState([]);

    useEffect(()=>{
        axios.post("https://api.kjeonse.com/api/admin/list/introduce", {},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
			},
		})
		.then(function (response) {
			console.log(response);
            setData(response.data.data);
		})
		.catch(function (error) {
			console.log(error);
		})
    },[])
    console.log(data);

    // 수정할때 업로드파일 유지
    let [pc_img , setPc_img] = useState([]);
    let [mobile_img, setMobile_img] = useState([]);

    let [pc_id , setPc_id] = useState("");
    let [mb_id , setMb_id] = useState("");

    useEffect(()=>{
        let pc_file = [];
        let mb_file = [];
        data.map((item,i)=>{
            if (item.type == 0) {
                setPc_id(item.id);
                pc_file.push({
                    uid: i,
                    name: item.fileAddress,
                    status: 'done',
                    url: item.fileAddress,
                    thumbUrl: item.fileAddress,
                })
            }else if (item.type == 1) {
                setMb_id(item.id);
                mb_file.push({
                    uid: i,
                    name: item.fileAddress,
                    status: 'done',
                    url: item.fileAddress,
                    thumbUrl: item.fileAddress,
                })
            }
        })
        setPc_img(pc_file);
        setMobile_img(mb_file);
    },[data])

    const pc_onChange = ({ fileList: newFileList }) => {
        setPc_img(newFileList);
    };

    const mb_onChange = ({ fileList: newFileList }) => {
        setMobile_img(newFileList);
    };

    function save() {
        // 등록하기
        let formData = new FormData();
        formData.append('pc_id', pc_id);
        formData.append('mb_id', mb_id);
        pc_img.map(item => {
            if (item.originFileObj != undefined) {
                formData.append('pc_file', item.originFileObj);
            }
        });
        mobile_img.map(item => {
            if (item.originFileObj != undefined) {
                formData.append('mb_file', item.originFileObj);
            }
        });
        axios.post("https://api.kjeonse.com/api/admin/update/introduce", formData,
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
            },
        })
        .then(function (response) {
            console.log(response);
            if (response.data.success) {
                alert("저장되었습니다");
                window.location.replace("/Homepage/IntroductionImg")
            }else{
                alert(response.data.msg)
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    const uploadAutoUploadLock = () => {
        return false;
    }

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>홈페이지 관리</li>
                    <li>소개 이미지 관리</li>
                </ul>
                <div className='title-wrap'>
                    <h2>소개 이미지 관리</h2>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <ul className='write-list'>
						<li>
                            <p className='labal-p'>
								소개 이미지
								(pc)
								1200px 최적
							</p>
                            <div className='input-wrap'>
								<Upload
									beforeUpload={uploadAutoUploadLock}
									listType="picture"
									fileList={pc_img}
                                    maxCount={1}
                                    onChange={pc_onChange}
									>
									<Button icon={<UploadOutlined />}>파일 업로드</Button>
								</Upload>
							</div>
                        </li>
                        <li>
                            <p className='labal-p'>
								소개 이미지
								(mobile)
								768px 최적
							</p>
                            <div className='input-wrap'>
                                <Upload
									beforeUpload={uploadAutoUploadLock}
									listType="picture"
									fileList={mobile_img}
                                    maxCount={1}
                                    onChange={mb_onChange}
									>
									<Button icon={<UploadOutlined />}>파일 업로드</Button>
								</Upload>
							</div>
                        </li>
                    </ul>
                    <div className='Save-btn-wrap'>
                    <button className='Save-btn' onClick={()=>{save()}}>저장</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroductionImg;