import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { data } from '../../date';

// API
import axios from 'axios';

// 엔트디자인
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload, Switch  } from 'antd';

// 에디터
import ReactQuill, { Quill } from "react-quill";
import  'quill/dist/quill.snow.css' ;
import ImageResize from 'quill-image-resize';
Quill.register('modules/ImageResize', ImageResize);


function NewsRg() {
	let navigate = useNavigate();
    let { id } = useParams();

    // 에디터
    let [content, setContent] = useState("");
    // 사용하고 싶은 옵션, 나열 되었으면 하는 순서대로 나열
    const modules = {
        toolbar: [
            //[{ 'font': [] }],
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
            ['clean']
        ],
    };
    // ========================================================================================================
    // 공지 구분
    let [top , setTop] = useState();
    
    const onChange = (checked) => {
		if (checked == true) {
            setTop(1);
        }else{
            setTop(0);
        }
	};
    useEffect(()=>{
        console.log(top)
    },[top])

    // 제목
    let [title, setTitle] = useState("");
    // 파일
    let [file, setFile] = useState([]);

    // 수정파일
    let [newfile, setNewfile] = useState([]);
    // 삭제파일
    let [removeFile, setRemoveFile] = useState([]);

    // 수정하기면 기존에 있던 내용뿌리기
    useEffect(()=>{
        if (id != "Registration") {
            axios.post("https://api.kjeonse.com/api/admin/detail/notice", {
                id: id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response.data.data);
                setTop(response.data.data.detail.top);
                setTitle(response.data.data.detail.title);
                setContent(response.data.data.detail.content);
                let file_copy = [];
                response.data.data.files.map((item,i)=>{
                    file_copy.push({
                        id: item.id,
                        name: item.originFileName,
                        status: 'done',
                        url: item.fileAddress,
                    })
                    setFile(file_copy);
                })
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    },[])

    const fileOnChange = ({ fileList: newFileList }) => {
        if (id == "Registration") {
            setFile(newFileList);
        }else{
            setFile(newFileList);
            setNewfile(newFileList);
        }
    };

    // 수정할떄 삭제
    function onRemove(file) {
        setRemoveFile([...removeFile,file.id])
    }

    function save() {
        if (id == "Registration") {
            // 등록하기
            let formData = new FormData();
            formData.append('top', top);
            formData.append('title', title);
            formData.append('content', content);
            file.map(item => {
                if (item.originFileObj != undefined) {
                    formData.append('files[]', item.originFileObj);
                }
            })
            axios.post("https://api.kjeonse.com/api/admin/register/notice", formData,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                if (response.data.success) {
                    alert("저장되었습니다");
                    navigate(-1)
                }else{
                    alert(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }else{
            // 수정하기
            let formData = new FormData();
            formData.append('id', id);
            formData.append('top', top);
            formData.append('title', title);
            formData.append('content', content);
            newfile.map(item => {
                if (item.originFileObj != undefined) {
                    formData.append('files[]', item.originFileObj);
                }
            })
            removeFile.map(item=>{
                if (item != undefined) {
                    formData.append('removeFiles[]', item);
                }
            })
            axios.post("https://api.kjeonse.com/api/admin/update/notice", formData,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('k-Jeonse-admin-token') ,
                },
            })
            .then(function (response) {
                console.log(response);
                if (response.data.success) {
                    alert("수정되었습니다");
                    navigate(-1)
                }else{
                    alert(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }

    const uploadAutoUploadLock = () => {
        return false;
    }

    return (
        <div className='Manager-content'>
            <div className='content-title-wrap'>
                <ul className='route-wrap'>
                    <li>홈페이지 관리</li>
                    <li>이용안내</li>
                </ul>
                <div className='title-wrap'>
                    <h2>이용안내</h2>
                </div>
            </div>
            <div className='content-wrap1'>
                <div className='White-box'>
                    <ul className='write-list'>
						<li>
                            <p className='labal-p'>공지</p>
                            <div className='input-wrap'>
								<Switch checked={top == 1 ? true : false} onChange={onChange} />
                            </div>
                        </li>
                        <li>
                            <p className='labal-p'>제목</p>
                            <div className='input-wrap'>
                                <div className='input-box'>
                                    <input type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className='labal-p'>내용</p>
                            <div className='input-wrap'>
                                <div className='Editor-wrap'>
                                    <ReactQuill
                                        // {...rest}
                                        value={content || ""}
                                        theme="snow" 
                                        modules={modules}
                                        // formats={formats}
                                        onChange={(e)=>{setContent(e)}}
                                    ></ReactQuill>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className='labal-p'>첨부파일</p>
							<div className='input-wrap'>
                            <Upload
                                beforeUpload={uploadAutoUploadLock}
                                fileList={file}
                                onChange={fileOnChange}
                                onRemove={onRemove}
                                >
                                <Button icon={<UploadOutlined />}>파일 업로드</Button>
                            </Upload>
							</div>
                        </li>
                    </ul>
                    <div className='Save-btn-wrap'>
                        <button className='cancel-btn' onClick={()=>{navigate("/Homepage/News")}}>취소</button>
                        <button className='Save-btn' onClick={()=>{save()}}>{id == "Registration" ? "저장" : "수정"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsRg;